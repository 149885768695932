import axios from "axios";
import "./ProfileForm.css";
import debounce from "lodash.debounce";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaCloudUploadAlt } from "react-icons/fa";
import { BASE_Server_URL } from "../requestMethods";

const ProfileForm = (props) => {
  const onChangeAddress = props.onChangeAddress || (() => {});
  const navigate = useNavigate();
  const [coach, setCoach] = useState({
    hourlyRate: "",
    bio: "",
    expertise: "",
    location: "",
  });

  const [showModal, setShowModal] = useState(false);
  const [showeModal, setShoweModal] = useState(false);
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [selectedService, setSelectedService] = useState(null);

  // States for form sections
  // const [services, setServices] = useState([]);
  const [services, setServices] = useState([
    { name: "", price: "", duration: "", details: "" },
  ]);
  console.log("services", services);

  const [service, setService] = useState({
    name: "",
    details: "",
    duration: "",
    bookingLink: "",
    price: "",
  });
  const [educations, setEducations] = useState([
    { abbreviation: "", course: "", universityName: "" },
  ]);
  console.log("educations", educations);
  // const [educations, setEducations] = useState([]);
  const [education, setEducation] = useState({
    abbreviation: "",
    course: "",
    universityName: "",
  });

  const [languages, setLanguages] = useState([]);
  const [language, setLanguage] = useState("");

  const [policies, setPolicies] = useState([]);
  const [policie, setPolicie] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const PROXY_URL = `${BASE_Server_URL}proxy`;

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    setSearchValue(value);
  };

  const allLanguageOptions = [
    { name: "English", value: "English", checked: false },
    { name: "Spanish", value: "Spanish", checked: false },
    { name: "Chinese", value: "Chinese", checked: false },
    { name: "French", value: "French", checked: false },
    { name: "German", value: "German", checked: false },
    { name: "Italian", value: "Italian", checked: false },
    { name: "Portuguese", value: "Portuguese", checked: false },
    { name: "Russian", value: "Russian", checked: false },
    { name: "Japanese", value: "Japanese", checked: false },
    { name: "Korean", value: "Korean", checked: false },
    { name: "Arabic", value: "Arabic", checked: false },
    { name: "Hindi", value: "Hindi", checked: false },
    { name: "Bengali", value: "Bengali", checked: false },
    { name: "Urdu", value: "Urdu", checked: false },
    { name: "Turkish", value: "Turkish", checked: false },
    { name: "Vietnamese", value: "Vietnamese", checked: false },
    { name: "Persian (Farsi)", value: "Persian (Farsi)", checked: false },
    { name: "Polish", value: "Polish", checked: false },
    { name: "Dutch", value: "Dutch", checked: false },
    { name: "Thai", value: "Thai", checked: false },
    { name: "Malay", value: "Malay", checked: false },
    { name: "Swedish", value: "Swedish", checked: false },
    { name: "Greek", value: "Greek", checked: false },
    { name: "Hebrew", value: "Hebrew", checked: false },
    { name: "Czech", value: "Czech", checked: false },
    { name: "Hungarian", value: "Hungarian", checked: false },
    { name: "Romanian", value: "Romanian", checked: false },
    { name: "Danish", value: "Danish", checked: false },
    { name: "Finnish", value: "Finnish", checked: false },
    { name: "Norwegian", value: "Norwegian", checked: false },
    { name: "Indonesian", value: "Indonesian", checked: false },
    { name: "Filipino (Tagalog)", value: "Filipino (Tagalog)", checked: false },
    { name: "Swahili", value: "Swahili", checked: false },
    { name: "Tamil", value: "Tamil", checked: false },
    { name: "Telugu", value: "Telugu", checked: false },
    { name: "Punjabi", value: "Punjabi", checked: false },
    { name: "Marathi", value: "Marathi", checked: false },
    { name: "Gujarati", value: "Gujarati", checked: false },
    { name: "Ukrainian", value: "Ukrainian", checked: false },
  ];

  const [selectedLanguages, setSelectedLanguages] = useState([]);
  // console.log(selectedLanguages);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const handleLanguageChange = (e) => {
    const { value, checked } = e.target;
    setSelectedLanguages((prev) =>
      checked ? [...prev, value] : prev.filter((lang) => lang !== value)
    );
  };

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const clearAll = () => setSelectedLanguages([]);

  const filteredOptions = allLanguageOptions.filter((option) =>
    option.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSuggestionClick = (suggestion) => {
    setSearchValue(suggestion);

    setSuggestions([]);
    onChangeAddress(suggestion);
  };

  const handleLanguageButtonClick = (e) => {
    e.preventDefault();
    toggleModal();
  };

  const getAvailableOptions = (selectedIndex) =>
    allOptions.filter(
      (option) =>
        !services.some(
          (service, index) => service.name === option && index !== selectedIndex
        )
    );

  const debouncedFetchSuggestions = debounce(async (inputValue) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${PROXY_URL}/?input=${inputValue}`);
      const { predictions } = response.data;

      const locationSuggestions = predictions
        .filter((prediction) => {
          const relevantTypes = [
            "locality",
            "administrative_area_level_1",
            "country",
          ];
          return prediction.types.some((type) => relevantTypes.includes(type));
        })
        .map((prediction) => prediction.description);

      setSuggestions(locationSuggestions);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching location suggestions:", error);
      setError("Error fetching suggestions. Please try again later.");
      setLoading(false);
    }
  }, 300);

  useEffect(() => {
    debouncedFetchSuggestions(searchTerm);
  }, [searchTerm]);

  // Unified change handler for coach fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCoach((prevCoach) => ({
      ...prevCoach,
      [name]: value,
    }));
  };

  // const handleLanguageChange = (e) => {
  //   const { name, value } = e.target;
  //   setLanguages((prevEducations) => ({
  //     ...prevEducations,
  //     [name]: value,
  //   }));
  // };

  // Add a new language field

  const handleSectionChange = (e, index) => {
    const updatedServices = [...services];
    updatedServices[index].name = e.target.value;
    setServices(updatedServices);

    handleViewService(index);
  };

  // const handleViewService = (index) => {
  //   console.log("Viewing service at index:", index);
  // };

  const addSpecialty = () => {
    setServices((prevServices) => [
      ...prevServices,
      { name: "", price: "", duration: "", details: "", bookingLink: "" },
    ]);
  };

  const addNewLanguageField = () => {
    setLanguages([...languages, { language: "" }]);
  };

  const [modalService, setModalService] = useState({
    name: "",
    details: "",
    duration: "",
    link: "",
    price: "",
    bookingLink: "",
  });

  const handleViewService = (index) => {
    setSelectedService(services[index]);
    setModalService(services[index]); // Set the modal service to the selected service
    setShowModal(true);
  };

  const handleServiceChange = (e) => {
    const { name, value } = e.target;
    setModalService((prevService) => ({
      ...prevService,
      [name]: value,
    }));
  };

  const updateService = () => {
    setServices((prevServices) =>
      prevServices.map((s) =>
        s.name === selectedService.name ? modalService : s
      )
    );
    setShowModal(false);
  };

  const handleSectionChange1 = (e, index) => {
    const { name, value } = e.target;
    const newEducations = [...educations];
    newEducations[index][name] = value;
    setEducations(newEducations);
  };

  const handleAddEducation = () => {
    setEducations([
      ...educations,
      { abbreviation: "", course: "", universityName: "" },
    ]);
  };

  // const handleModaleToggle = () => {
  //   // Implement modal toggle logic here
  // };
  // const handleSectionChange = (e, setFunction) => {
  //   const { name, value } = e.target;
  //   setFunction((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));

  //   // Check if the selected service is not empty
  //   if (name === "name" && value) {
  //     setShowModal(true);
  //   }
  // };

  // Add new service to services list
  // const addNewService = () => {
  //   if (selectedService.name) {
  //     setServices((prevServices) => [...prevServices, selectedService]);
  //     setSelectedService({ name: "", price: "", duration: "", details: "" });
  //     setShowModal(false);
  //   }
  // };

  const addNewService = () => {
    if (service.name) {
      setServices((prevServices) => [...prevServices, service]);
      setService({
        name: "",
        price: "",
        duration: "",
        details: "",
        bookingLink: "",
      });
      setShowModal(false);
    }
  };

  // Handle modal open/close
  const handleModalToggle = () => {
    setShowModal(!showModal);
  };

  const handleModaleToggle = () => {
    setShoweModal(!showeModal);
  };

  // Add new education to educations list
  const addNewEducation = () => {
    setEducations((prevEducations) => [...prevEducations, education]);
    setEducation({ abbreviation: "", course: "", universityName: "" });
    setShoweModal(false);
  };

  useEffect(() => {
    if (searchValue) {
      setCoach((prevCoach) => ({
        ...prevCoach,
        location: searchValue,
      }));
    }
  }, [searchValue, setCoach]);

  const addNewLanguage = () => {
    setLanguages((prevLang) => [...prevLang, language]);
    setLanguage("");
  };

  // Add new policy to policies list
  const addNewPolicies = () => {
    setPolicies((prevPol) => [...prevPol, policie]);
    setPolicie("");
  };

  // File upload function
  const upload = async (file) => {
    const data = new FormData();
    data.append("file", file);
    data.append("upload_preset", "scholist");

    try {
      const res = await axios.post(
        "https://api.cloudinary.com/v1_1/db82bjix0/image/upload",
        data
      );

      const { url } = res.data;
      return url;
    } catch (err) {
      console.log(err);
    }
  };

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const uploadedUrl = await upload(selectedFile);
      if (uploadedUrl) {
        setFileUrl(uploadedUrl);
      }
    }
  };

  // Form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const url = await upload(file);
    try {
      const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
      const currentUser = user && JSON.parse(user).currentUser;
      const TOKEN = currentUser?.tokens.access.token;

      const client = axios.create({
        baseURL: "http://localhost:3000/v1/",
        headers: { Authorization: `Bearer ${TOKEN}` },
      });
      const formattedServices = services.map((service) => {
        const durationValue = parseInt(service.duration) || 0;

        return {
          name: service.name,
          details: service.details,
          price: parseFloat(service.price),
          duration: durationValue,
          bookingLink: service.bookingLink || "",
        };
      });
      console.log("multiple", policie, selectedLanguages);

      await client.post("/coach", {
        ...coach,
        img: url,
        services: formattedServices,
        education: educations,
        languages: selectedLanguages,
        policies: policie,
      });

      navigate("/welcome");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="coach-application-form">
        <div class="container">
          {/* <img
            className="headerlogoo"
            alt=""
            src="/Logo Bird Vector.svg"
            //onClick={onButtonHomeClick}
          /> */}
          <h1 class="headerText">Scholist Coach Application</h1>
        </div>
        <div className="form-body">
          <div className="sub-header">
            <p>Please submit the following information for approval</p>
          </div>
          <div className="top-section">
            <div className="image-upload-container">
              <label htmlFor="file-upload" className="custom-file-upload">
                {fileUrl ? (
                  <img
                    src={fileUrl}
                    alt="Uploaded preview"
                    className="uploaded-image"
                  />
                ) : (
                  <FaCloudUploadAlt className="cloud-icon" />
                )}
              </label>
              <input
                id="file-upload"
                type="file"
                onChange={handleFileChange}
                className="file-input"
              />
              <p>Upload Photo</p>
            </div>
            <div className="top-section-right">
              <div className="bio-field">
                <label>Bio: </label>
                <textarea
                  type="text"
                  name="bio"
                  value={coach.bio}
                  onChange={handleChange}
                  required
                />
              </div>

              <div>
                <label>
                  Expertise:
                  <input
                    type="text"
                    name="expertise"
                    value={coach.expertise}
                    onChange={handleChange}
                  />
                </label>
              </div>

              <div style={{ position: "relative" }}>
                <label>
                  Location:
                  <input
                    type="text"
                    name="location"
                    className="locations-input"
                    value={searchValue}
                    onChange={handleInputChange}
                    required="required"
                  />
                  {suggestions.length > 0 && (
                    <ul className="suggestions">
                      {suggestions.map((suggestion, index) => (
                        <li
                          key={index}
                          onClick={() => handleSuggestionClick(suggestion)}
                          style={{
                            padding: "8px",
                            cursor: "pointer",
                            borderBottom: "1px solid #f0f0f0",
                          }}
                          onMouseEnter={(e) =>
                            (e.target.style.backgroundColor = "#f0f0f0")
                          }
                          onMouseLeave={(e) =>
                            (e.target.style.backgroundColor = "#fff")
                          }
                        >
                          {suggestion}
                        </li>
                      ))}
                    </ul>
                  )}
                </label>
              </div>

              {/* <div>
                <label>
                  Average Hourly Rate:
                  <input
                    type="text"
                    name="hourlyRate"
                    value={coach.hourlyRate}
                    onChange={handleChange}
                  />
                </label>
              </div> */}

              {/* <div>
                <label>
                  hourlyRate:
                  <input
                    type="text"
                    name="hourlyRate"
                    value={coach.hourlyRate}
                    onChange={handleChange}
                  />
                </label>
              </div> */}
            </div>
          </div>

          {/* Repeat the above pattern for other fields */}
          {/* <div>
            {services.map((service, index) => (
              <div key={index} className="">
                <p>name: {service.name}</p>
                <p>details: {service.details}</p>
                <p>price: {service.price}</p>
                <p>duration: {service.duration}</p>
                <p>link: {service.bookingLink}</p>
              </div>
            ))}
          </div> */}

          <div className="sevice-bar">
            <label>
              <p>Select primary coaching specialty</p>

              <div className="scrollable-container">
                {services.map((service, index) => (
                  <div
                    key={index}
                    style={{ display: "flex", alignItems: "left", gap: "6px" }}
                  >
                    <select
                      name="name"
                      onChange={(e) => handleSectionChange(e, index)}
                      value={service.name} 
                      style={{ width: "100%" }}
                    >
                      <option value="" disabled>
                        Select a service
                      </option>
                      <option value="Essay review">Essay Review</option>
                      <option value="Scholarship consultation">
                        Scholarship Consultation
                      </option>
                      <option value="Interview prep">Interview Prep</option>
                      <option value="FAFSA assistance">FAFSA Assistance</option>
                      <option value="Resume review">Resume Review</option>
                      <option value="College planning">College Planning</option>
                    </select>
                  </div>
                ))}
              </div>

              <button className="add-form" type="button" onClick={addSpecialty}>
                + Add another specialty
              </button>
            </label>
          </div>

          <div className="second-section">
            {/* <div>
              {educations.map((education, index) => (
                <div key={index}>
                  <p>abbreviation: {education.abbreviation}</p>
                  <p>course: {education.course}</p>
                  <p>universityName: {education.universityName}</p>
                </div>
              ))}
            </div> */}

            <div className="education-bar">
              <label>
                <p>Educations:</p>
                <div className="education-inputs">
                  {educations.map((education, index) => (
                    <div key={index} className="education-box">
                      <input
                        type="text"
                        name="abbreviation"
                        placeholder="Abbreviation"
                        value={education.abbreviation}
                        onChange={(e) => handleSectionChange1(e, index)}
                      />
                      <input
                        type="text"
                        name="course"
                        placeholder="Course"
                        value={education.course}
                        onChange={(e) => handleSectionChange1(e, index)}
                      />
                      <input
                        type="text"
                        name="universityName"
                        placeholder="University Name"
                        value={education.universityName}
                        onChange={(e) => handleSectionChange1(e, index)}
                      />
                      {/* <button
                        onClick={handleModaleToggle}
                        className="eye-button"
                      >
                        <svg
                          width="24"
                          height="24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          className="feather feather-eye"
                        >
                          <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                          <circle cx="12" cy="12" r="3"></circle>
                        </svg>
                      </button> */}
                    </div>
                  ))}
                </div>
                <button
                  className="add-form"
                  type="button"
                  onClick={handleAddEducation}
                >
                  Add Education
                </button>
              </label>
            </div>
          </div>
          {/* <div>
            {Array.isArray(languages) &&
              languages.map((language, index) => (
                <div key={index}>
                  <p>language: {language}</p>
                </div>
              ))}
          </div>
          <div className="language-selection">
            <p>Select Languages:</p>
            <div className="checkbox-group">
              {allLanguageOptions.map((option) => (
                <label key={option.value}>
                  <input
                    type="checkbox"
                    value={option.value}
                    checked={selectedLanguages.includes(option.value)}
                    onChange={handleLanguageChange}
                  />
                  {option.name}
                </label>
              ))}
            </div>
          </div> */}

          <div className="policy-bar">
            <label>
              <p>Languages:</p>
            </label>

            <button className="trigger" onClick={toggleModal} style={{ width: "100%" }}>
              Languages <span className="spant">▾</span>
            </button>

            {isModalOpen && (
              <div className="modal">
                <div className="modal-content">
                  <button className="cancel-button" onClick={toggleModal}>
                    +
                  </button>
                  {/* Search Input */}
                  <input
                    type="text"
                    placeholder="Search Language"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="search-input"
                  />

                  {/* Checkbox Group */}
                  <div className="checkbox-group">
                    {filteredOptions.map((option) => (
                      <label key={option.value} className="checkbox-label">
                        <input
                          type="checkbox"
                          value={option.value}
                          checked={selectedLanguages.includes(option.value)}
                          onChange={handleLanguageChange}
                        />
                        {option.name}
                      </label>
                    ))}
                  </div>

                  {/* Clear & Apply Buttons */}
                  <div className="button-group">
                    <button onClick={clearAll} className="clear-button">
                      Clear All
                    </button>
                    <button onClick={toggleModal} className="apply-button">
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* <div>
            {Array.isArray(policies) &&
              policies.map((policie, index) => (
                <div key={index}>
                  <p>policie: {policie}</p>
                </div>
              ))}
          </div> */}

          <div className="policy-bar">
            <label>
              <p> Policies:</p>
              <select
                name="policie"
                onChange={(e) => setPolicie(e.target.value)}
                value={policie}
                style={{ width: "100%" }}
              >
                <option value="" disabled>
                  Select Policy
                </option>
                <option value="No refund after payment">
                  No refund after payment
                </option>
                <option value="24-hour Notice Required">
                  24-hour Notice Required
                </option>
                <option value="48-hour Notice Required">
                  48-hour Notice Required
                </option>
              </select>
              {/* <div className="scrollable-container">
                {policies.map((policie, index) => (
                  <div
                    key={index}
                    style={{ display: "flex", alignItems: "left", gap: "6px" }}
                  >
                  </div>
                ))}
              </div> */}
              {/* <div className="add-form" onClick={addNewPolicies}>
                Add Policy
              </div> */}
            </label>
          </div>

          <div className="coach-submit-button">
            <button type="submit">
              <p> Submit Application for review </p>
            </button>
          </div>
        </div>
        {showModal && (
          <div className="modal">
            <div className="modal-content">
              {/* Cancel button at the top */}
              <button className="cancel-button" onClick={handleModalToggle}>
                +
              </button>

              <h2>Add a Service</h2>

              <label>Service Name:</label>
              <input
                type="text"
                name="name"
                value={service.name}
                onChange={handleServiceChange}
              />

              {/* Price and Duration on the same line */}
              <div className="flex-row">
                <div>
                  <label>Price:</label>
                  <input
                    type="text"
                    name="price"
                    value={service.price}
                    onChange={handleServiceChange}
                  />
                </div>

                <div>
                  <label>Duration:</label>
                  <select
                    name="duration"
                    value={service.duration}
                    onChange={handleServiceChange}
                  >
                    <option value="">Select Duration</option>
                    <option value="30 mins">30 mins</option>
                    <option value="1 hour">1 hour</option>
                    <option value="2 hours">2 hours</option>
                  </select>
                </div>
              </div>

              <label>Description:</label>
              <textarea
                name="details"
                value={service.details}
                onChange={handleServiceChange}
              />
              <button className="save-button" onClick={addNewService}>
                Save
              </button>
            </div>
          </div>
        )}
        {showModal && (
          <div className="modal">
            <div className="modal-content">
              <button className="cancel-button" onClick={handleModalToggle}>
                +
              </button>

              <h2>Edit Service</h2>

              <label>Service Name:</label>
              <input
                type="text"
                name="name"
                value={modalService.name}
                onChange={handleServiceChange}
              />

              {/* Price and Duration on the same line */}
              <div className="flex-row">
                <div>
                  <label>Price:</label>
                  <input
                    type="text"
                    name="price"
                    value={modalService.price}
                    onChange={handleServiceChange}
                  />
                </div>

                <div>
                  <label>Duration:</label>
                  <select
                    name="duration"
                    value={modalService.duration}
                    onChange={handleServiceChange}
                  >
                    <option value="">Select Duration</option>
                    <option value="30 mins">30 mins</option>
                    <option value="1 hour">1 hour</option>
                    <option value="2 hours">2 hours</option>
                  </select>
                </div>
              </div>

              <label>Description:</label>
              <textarea
                name="details"
                value={modalService.details}
                onChange={handleServiceChange}
              />
              {/* <label>Booking Link:</label>
              <input
                type="text"
                name="bookingLink"
                value={modalService.bookingLink}
                onChange={handleServiceChange}
              /> */}
              <button className="save-button" onClick={updateService}>
                Save
              </button>
            </div>
          </div>
        )}
        {showeModal && (
          <div className="modal">
            <div className="modal-content">
              {/* Cancel button at the top */}
              <button className="cancel-button" onClick={handleModaleToggle}>
                +
              </button>

              <h2>Add Education</h2>

              <label>Abbrev:</label>
              <input
                type="text"
                name="abbreviation"
                placeholder="abbreviation"
                value={education.abbreviation}
                onChange={(e) => handleSectionChange(e, setEducation)}
              />

              <label>Course:</label>
              <input
                type="text"
                name="course"
                placeholder="course"
                value={education.course}
                onChange={(e) => handleSectionChange(e, setEducation)}
              />

              <label>University Name:</label>
              <input
                type="text"
                name="universityName"
                placeholder="university name"
                value={education.universityName}
                onChange={(e) => handleSectionChange(e, setEducation)}
              />

              {/* Save button (full width) */}
              <button className="save-button" onClick={addNewEducation}>
                Save
              </button>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default ProfileForm;
