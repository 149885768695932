import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./WelcomePath.css";
const WelcomePathMobile = () => {
  const navigate = useNavigate();

  const onWelcomePathHeaderButtonsStudenContainerClick = useCallback(() => {
    navigate("/welcomesignupmobile");
  }, [navigate]);

  const onWelcomePathHeaderButtonsCoachContainerClick = useCallback(() => {
    navigate("/coachsignup");
  }, [navigate]);

  return (
    <div className="welcomepath">
      <div className="welcomepathbody">
        <div className="welcomepathbody1">
          <div className="welcomepathheader">
            {/* <img
              className="welcomepathheaderlogobox-icon"
              alt=""
              src="/undefined.png"
            /> */}
            <svg
              className="welcomepathheaderlogobox-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="104"
              height="96"
              viewBox="0 0 104 96"
              fill="none"
            >
              <g clip-path="url(#clip0_660_12705)">
                <path
                  d="M70.0058 59.9629L69.8047 60.1004L69.8702 60.0103C69.9169 59.9866 69.959 59.9629 70.0058 59.9629V59.9629Z"
                  fill="#010101"
                />
                <path
                  d="M70.4735 59.7821C70.3192 59.8484 70.1602 59.8959 70.0059 59.9622C70.0713 59.9148 70.1415 59.8959 70.1836 59.8247C70.2958 59.8247 70.3847 59.801 70.4735 59.7773V59.7821Z"
                  fill="#010101"
                />
                <path
                  d="M70.1844 59.8301C70.1377 59.8964 70.0722 59.9201 70.0067 59.9675C69.96 59.9675 69.9179 59.9912 69.8711 60.0149L69.9833 59.8775C70.0488 59.8775 70.119 59.8538 70.1844 59.8301V59.8301Z"
                  fill="#010101"
                />
                <path
                  d="M68.9766 60.0512C69.3133 60.0275 69.6454 59.9612 69.9821 59.8711L69.8698 60.0086C69.5144 60.146 69.1777 60.2551 68.8223 60.3688C68.8877 60.2551 68.9345 60.165 68.9766 60.0512V60.0512Z"
                  fill="#010101"
                />
                <path
                  d="M68.332 60.1665C68.5565 60.1428 68.7576 60.1191 68.9774 60.0527C68.9307 60.1665 68.8886 60.2566 68.8231 60.3703C68.7576 60.394 68.7108 60.4177 68.6454 60.4367L68.332 60.1902V60.1665V60.1665Z"
                  fill="#010101"
                />
                <path
                  d="M83.6865 26.5107C83.6865 37.2095 80.0246 47.0503 73.8887 54.7628C77.3027 48.3824 79.2202 41.0539 79.2202 33.2988C79.2202 28.5964 78.5046 24.0695 77.2138 19.8174L77.1671 19.68L83.172 19.6562C83.5087 21.8937 83.6865 24.1785 83.6865 26.5107V26.5107Z"
                  fill="#010101"
                />
                <path
                  d="M99.2402 19.5898C98.4124 38.0011 86.6972 53.5397 70.4735 59.7827C70.3847 59.8064 70.2958 59.8301 70.1836 59.8301C82.3665 51.7574 90.3778 37.821 90.3778 21.9884C90.3778 21.1968 90.3544 20.4052 90.3123 19.6372L99.2402 19.5898V19.5898Z"
                  fill="#010101"
                />
                <path
                  d="M83.6858 26.5099C83.6858 24.1824 83.508 21.8976 83.1713 19.6555L77.1664 19.6792L77.2131 19.8167C78.5086 24.0687 79.2195 28.5909 79.2195 33.298C79.2195 41.0579 77.302 48.3863 73.888 54.762C80.0239 47.0496 83.6858 37.2087 83.6858 26.5099V26.5099ZM93.7267 47.9976C93.7267 74.2351 72.7515 95.4953 46.8657 95.4953C20.9799 95.4953 0 74.2351 0 47.9976C0 21.7602 20.9799 0.5 46.8657 0.5C60.1898 0.5 72.2371 6.13146 80.7628 15.2044H103.768C103.768 26.1307 99.9515 36.1469 93.5677 43.9731C93.6566 45.3099 93.7221 46.6419 93.7221 47.9976H93.7267ZM61.3683 19.7266C66.9711 27.2826 70.2962 36.6684 70.2962 46.8694C70.2962 51.5054 69.6041 55.9613 68.332 60.1707C67.1488 60.3508 65.9422 60.4409 64.7169 60.4409C54.8536 60.4409 46.2858 54.7857 41.9785 46.5092L41.8429 46.4191C38.63 38.7067 31.1097 33.3028 22.3175 33.3028C17.4536 33.3028 12.9452 34.9761 9.37688 37.7823C16.3827 41.2901 21.2044 48.5949 21.2044 57.0516C21.2044 71.4146 32.6952 83.0615 46.8657 83.0615C60.2552 83.0615 71.255 72.6566 72.4148 59.4027L72.4616 59.2463C71.8162 59.4502 71.1427 59.6303 70.4739 59.7915C86.6976 53.5485 98.4128 38.0099 99.2406 19.5986H99.3061L99.2593 19.3284C99.2593 19.4185 99.2593 19.5085 99.236 19.5986L90.308 19.646C90.3548 20.4139 90.3735 21.2056 90.3735 21.9972C90.3735 37.8297 82.3622 51.7614 70.1793 59.8389C70.1138 59.8626 70.0437 59.8863 69.9782 59.8863C69.6415 59.9763 69.3094 60.0427 68.9727 60.0664C72.6533 53.5058 74.7532 45.9072 74.7532 37.8345C74.7532 31.3213 73.3922 25.1447 70.9603 19.5607L71.0071 19.7171L61.3683 19.7408V19.7266Z"
                  fill="#4A93CF"
                />
                <path
                  d="M70.2971 46.8699C70.2971 36.6688 66.9719 27.283 61.3691 19.727L71.0079 19.7033L70.9612 19.5469C73.3931 25.1357 74.754 31.3075 74.754 37.8207C74.754 45.8934 72.6541 53.4968 68.9735 60.0526C68.7491 60.119 68.548 60.1427 68.3281 60.1664C69.6002 55.957 70.2924 51.5011 70.2924 46.8651L70.2971 46.8699Z"
                  fill="#010101"
                />
                <path
                  d="M25.6664 44.6043C26.8984 44.6043 27.8972 43.5919 27.8972 42.3431C27.8972 41.0944 26.8984 40.082 25.6664 40.082C24.4343 40.082 23.4355 41.0944 23.4355 42.3431C23.4355 43.5919 24.4343 44.6043 25.6664 44.6043Z"
                  fill="#010101"
                />
                <path
                  d="M27.8975 42.3425C27.8975 41.1006 26.892 40.0814 25.6667 40.0814C24.4414 40.0814 23.4359 41.1006 23.4359 42.3425C23.4359 43.5845 24.4414 44.6037 25.6667 44.6037C26.892 44.6037 27.8975 43.5845 27.8975 42.3425V42.3425ZM25.6667 37.8203C28.122 37.8203 30.1283 39.8539 30.1283 42.3425C30.1283 44.8312 28.122 46.8648 25.6667 46.8648C23.2114 46.8648 21.2051 44.8312 21.2051 42.3425C21.2051 39.8539 23.2114 37.8203 25.6667 37.8203V37.8203Z"
                  fill="#4A93CF"
                />
                <path
                  d="M30.1277 42.3422C30.1277 39.8535 28.1214 37.8199 25.6661 37.8199C23.2108 37.8199 21.2045 39.8535 21.2045 42.3422C21.2045 44.8308 23.2108 46.8644 25.6661 46.8644C28.1214 46.8644 30.1277 44.8308 30.1277 42.3422V42.3422ZM64.7169 60.4358C65.9422 60.4358 67.1488 60.3458 68.3321 60.1656V60.1893L68.6454 60.4358C68.7109 60.4121 68.7576 60.3884 68.8231 60.3695C69.1786 60.2557 69.5153 60.1419 69.8707 60.0092L69.8052 60.0993L70.0063 59.9618C70.1607 59.8954 70.3197 59.848 70.474 59.7817C71.1428 59.6252 71.8116 59.4404 72.4616 59.2365L72.4149 59.393C71.255 72.6468 60.2553 83.0517 46.8658 83.0517C32.6952 83.0517 21.2045 71.4048 21.2045 57.0418C21.2045 48.5804 16.3827 41.2756 9.37695 37.7725C12.9453 34.9663 17.4537 33.293 22.3175 33.293C31.1098 33.293 38.63 38.6969 41.843 46.4093L41.9786 46.4994C46.2859 54.7759 54.8537 60.4311 64.7169 60.4311V60.4358Z"
                  fill="#010101"
                />
              </g>
              <defs>
                <clipPath id="clip0_660_12705">
                  <rect
                    width="104"
                    height="95"
                    fill="white"
                    transform="translate(0 0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className="welcomepathheaderwelcome">
            <div className="welcomepathheaderwelcometext">
              Welcome to Scholist!
            </div>
          </div>
          <div className="welcomepathheadercontinue">
            <div className="welcomepathheadercontinuetext">
              How would you like to continue?
            </div>
          </div>
          <div className="welcomepathheaderbuttons">
            <div className="welcomepathheaderbuttonsbox">
              <div
                className="welcomepathheaderbuttonsstuden"
                onClick={onWelcomePathHeaderButtonsStudenContainerClick}
              >
                <div className="welcomepathbuttonscoachtext">Student</div>
              </div>
              <div className="welcomepathheaderbuttonsstuden2">OR</div>
              <div
                className="welcomepathbuttonscoach"
                onClick={onWelcomePathHeaderButtonsCoachContainerClick}
              >
                <div className="welcomepathbuttonscoachtext">Coach</div>
              </div>
            </div>
          </div>
          <div className="welcomepathbuttonsnothanks" />
        </div>
      </div>
    </div>
  );
};

export default WelcomePathMobile;
