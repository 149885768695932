import { useState, useCallback, useEffect } from "react";
import BookCoach from "../components/BookCoach";
import PortalPopup from "../components/PortalPopup";
import "./CoachAvailabilityCalendar.css";

import { format, startOfToday, eachDayOfInterval, startOfMonth, startOfWeek, endOfMonth, endOfWeek, isToday, isSameMonth, isEqual, add, parse, getDay, formatDistance, formatRelative, subDays } from 'date-fns'



import { InlineWidget } from "react-calendly";
import { redirect } from "react-router-dom";


const calendarStyles = {
 
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(50px, 1fr))',
  gridGap: '10px',
  width: '442px',
};




const CoachAvailabilityCalendar = () => {
  let today = startOfToday()

  const [isBookCoachPopupOpen, setBookCoachPopupOpen] = useState(false);
  const [selectedDay, setSelectedDay] = useState(today)
  const [currentMonth, setCurrentMonth] = useState(format(today, 'MMM-yyyy'))
  
  let firstDayCurrentMonth = parse(currentMonth, 'MMM-yyyy', new Date())

  let newDays = eachDayOfInterval(
    {
      start: startOfWeek(firstDayCurrentMonth),
      end: endOfWeek( endOfMonth(firstDayCurrentMonth))})



  const openBookCoachPopup = useCallback(() => {
    setBookCoachPopupOpen(true);
  }, []);

  const closeBookCoachPopup = useCallback(() => {
    setBookCoachPopupOpen(false);
  }, []);

  function nextMonth() {
    
   let firstDayNextMonth =  add(firstDayCurrentMonth, {months: 1})
   setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'))
  }

  function prevMonth() {
    
    let firstDayNextMonth =  add(firstDayCurrentMonth, {months: -1})
    setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'))
   }
 
 
  


  

  console.log(newDays)


  // useEffect(() => {
  //   const clientId = 'DrRSCXYU7mlVLiMUOLTw5TJXYf190SnjBt_jiDf805E';
  //   const redirectUri = 'http://localhost:3001/dashboard';

  //   // Construct the Calendly authorization URL
  //   const authorizationUrl = `https://auth.calendly.com/oauth/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}`;

  //   // Redirect the user to Calendly for authorization
  //   window.location.href = authorizationUrl;
  // }, []);
  return (
//     <>
//       <div className="coach-availabilitycalendar">
//         <div className="coahavailabilitybackheaderbox">
//           <div className="coahavailabilitybackheaderbox1">{`< Dashboard     `}</div>
//         </div>
//         <div className="allcalendarbox">
//           <div className="allcalendarbox1">
//             <div className="allcalendarbox2">
//               <div className="sidecoachavailability1">
//                 <div className="wrapper-coachavailabilityprofi">
//                   <img
//                     className="coachavailabilityprofileimg-icon"
//                     alt=""
//                     src="/coachavailabilityprofileimg@2x.png"
//                   />
//                 </div>
//                 <div className="coachavailabilitydetailsbottom">
//                   <div className="coachavailabilitydetailsname">
//                     <div className="hailey-johnson">Hailey Johnson</div>
//                     <b className="min-11">60 Min 1:1</b>
//                   </div>
//                   <div className="coachavailabilitydetailshours">
                 
//                       <svg xmlns="http://www.w3.org/2000/svg"  className="coachavailabilitydetailsclock-icon" width="32" height="31" viewBox="0 0 32 31" fill="none">
//    <path d="M19.7055 22.2342L14.2966 16.8254V9.20721H17.3439V15.5684L21.8386 20.0631L19.7055 22.2342ZM14.2966 6.15995V3.11269H17.3439V6.15995H14.2966ZM24.9621 16.8254V13.7781H28.0093V16.8254H24.9621ZM14.2966 27.4908V24.4435H17.3439V27.4908H14.2966ZM3.63124 16.8254V13.7781H6.6785V16.8254H3.63124ZM15.8203 30.538C13.7126 30.538 11.7319 30.1378 9.87812 29.3374C8.02438 28.538 6.41187 27.4527 5.0406 26.0814C3.66933 24.7101 2.584 23.0976 1.7846 21.2439C0.984191 19.3901 0.583984 17.4094 0.583984 15.3017C0.583984 13.194 0.984191 11.2133 1.7846 9.35957C2.584 7.50582 3.66933 5.89331 5.0406 4.52205C6.41187 3.15078 8.02438 2.06494 9.87812 1.26453C11.7319 0.465128 13.7126 0.0654297 15.8203 0.0654297C17.928 0.0654297 19.9087 0.465128 21.7624 1.26453C23.6162 2.06494 25.2287 3.15078 26.6 4.52205C27.9712 5.89331 29.0566 7.50582 29.856 9.35957C30.6564 11.2133 31.0566 13.194 31.0566 15.3017C31.0566 17.4094 30.6564 19.3901 29.856 21.2439C29.0566 23.0976 27.9712 24.7101 26.6 26.0814C25.2287 27.4527 23.6162 28.538 21.7624 29.3374C19.9087 30.1378 17.928 30.538 15.8203 30.538ZM15.8203 27.4908C19.2231 27.4908 22.1052 26.3099 24.4669 23.9483C26.8285 21.5867 28.0093 18.7045 28.0093 15.3017C28.0093 11.899 26.8285 9.01675 24.4669 6.65513C22.1052 4.2935 19.2231 3.11269 15.8203 3.11269C12.4175 3.11269 9.53531 4.2935 7.17368 6.65513C4.81206 9.01675 3.63124 11.899 3.63124 15.3017C3.63124 18.7045 4.81206 21.5867 7.17368 23.9483C9.53531 26.3099 12.4175 27.4908 15.8203 27.4908Z" fill="#787878"/>
//  </svg>
//                     <div className="min">20 min</div>
//                   </div>
//                   <div className="coachavailabilitydetailsconfre">
                   
//                     <svg xmlns="http://www.w3.org/2000/svg"   className="coachavailabilitydetailsvideo-icon" width="31" height="23" viewBox="0 0 31 23" fill="none">
//   <path d="M5.51863 1.86572H19.2899C21.4697 1.86572 23.2245 3.62058 23.2245 5.80037V17.6043C23.2245 19.7841 21.4697 21.539 19.2899 21.539H5.51863C3.33884 21.539 1.58398 19.7841 1.58398 17.6043V5.80037C1.58398 3.62058 3.33884 1.86572 5.51863 1.86572Z" stroke="#787878" stroke-width="2.34405" stroke-linecap="round" stroke-linejoin="round"/>
//    <path d="M23.2246 15.636L29.1266 19.5706V3.83203L23.2246 7.76668" stroke="#787878" stroke-width="2.34405" stroke-linecap="round" stroke-linejoin="round"/>
//  </svg>
//                     <div className="web-conferencing-details">
//                       Web conferencing details provided upon confirmation
//                     </div>
//                   </div>
//                   <div className="coachavailabilitydetailsbottom-child" />
//                 </div>
//               </div>
//               <div className="coachavailabilitydate">
//                 <div className="select-date">{`Select Date & Time`}</div>
//                 <div className="coachavailabilitydetailscalend">
//                   <div className="coachavailabilitycalendarbody">
//                     <div className="coachavailabilitycalendaheader">
//                       <div className="coachavailabilitycalendarheade">
                       
//                         {format(firstDayCurrentMonth, 'MMM yyyy')}
//                       </div>
//                       <div className="coachavailabilitycalendaarrows">
//                               <div className="coachavailabilitycalendararrow" onClick = {  prevMonth} >
                       
//                                 <svg xmlns="http://www.w3.org/2000/svg"  width="15" height="15" viewBox="0 0 15 15" fill="none">
//       <path fill-rule="evenodd" clip-rule="evenodd" d="M9.84734 1.39487C10.2211 1.00368 10.8457 1.00368 11.2195 1.39487C11.5699 1.76161 11.5699 2.33906 11.2195 2.7058L6.47029 7.67676L11.2195 12.6477C11.5699 13.0145 11.5699 13.5919 11.2195 13.9586C10.8457 14.3498 10.2211 14.3498 9.84734 13.9586L3.8457 7.67676L9.84734 1.39487Z" fill="#AFAFAF"/>
//     </svg>
//                               </div>
                         
//                                <div className="coachavailabilitycalendararrow" onClick = { nextMonth}>

//     <svg xmlns="http://www.w3.org/2000/svg"    width="15" height="15" viewBox="0 0 15 15" fill="none">
//       <path fill-rule="evenodd" clip-rule="evenodd" d="M5.84407 1.39487C5.47033 1.00368 4.84566 1.00368 4.47192 1.39487C4.12155 1.76161 4.12155 2.33906 4.47193 2.7058L9.22111 7.67676L4.47192 12.6477C4.12155 13.0145 4.12155 13.5919 4.47192 13.9586C4.84566 14.3498 5.47033 14.3498 5.84407 13.9586L11.8457 7.67676L5.84407 1.39487Z" fill="black"/>
//     </svg>
//     </div>
//                             </div>
                          
//                     </div>
//                     <div className="calendarrowfordays-header">
//                       <div className="calendarrowfordayscell">
//                         <div className="bookingdaycalendarinnercell">
//                           <div className="bookingcalendarcelldaynum">Sun</div>
//                         </div>
//                       </div>
//                       <div className="calendarrowfordayscell1">
//                         <div className="bookingdaycalendarinnercell">
//                           <div className="bookingcalendarcelldaynum">Mon</div>
//                         </div>
//                       </div>
//                       <div className="calendarrowfordayscell1">
//                         <div className="bookingdaycalendarinnercell">
//                           <div className="bookingcalendarcelldaynum">Tue</div>
//                         </div>
//                       </div>
//                       <div className="calendarrowfordayscell1">
//                         <div className="bookingdaycalendarinnercell">
//                           <div className="bookingcalendarcelldaynum">Wed</div>
//                         </div>
//                       </div>
//                       <div className="calendarrowfordayscell1">
//                         <div className="bookingdaycalendarinnercell">
//                           <div className="bookingcalendarcelldaynum">Thu</div>
//                         </div>
//                       </div>
//                       <div className="calendarrowfordayscell1">
//                         <div className="bookingdaycalendarinnercell">
//                           <div className="bookingcalendarcelldaynum">Fri</div>
//                         </div>
//                       </div>
//                       <div className="calendarrowfordayscell1">
//                         <div className="bookingdaycalendarinnercell">
//                           <div className="bookingcalendarcelldaynum">Sat</div>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="calendarrowfordays" >

//                      {
//                       newDays.map((day, dayIdx) => (
//                       <div 
//                       className="calendarrowdayscellsselectedro" 
//                       key={day.toString()} 
//                       onClick={ 
//                         () => setSelectedDay(day)
//                       }
//                       style={{  gridColumn: dayIdx === 0 ? getDay(day) + 1 : 'auto',}}
                    
                    
                      
//                       >
//                         <div className="daysbookingcalendarinnercell" 
//                        style={{
//                         backgroundColor: isEqual(day, selectedDay) ? 'rgba(74, 147, 207, 0.65)' : 'inherit',
//                         borderRadius: isEqual(day, selectedDay) ? '9.456px' : 'inherit',
//                        width: '30.732px'
                       
                       
                     
//                       }}>                      
//                           <div className="daysbookingcalendarcellnudayna" style={{
//     color:
//       isToday(day) && isSameMonth(day, firstDayCurrentMonth) ? 'blue' :
//       !isToday(day) && isSameMonth(day, firstDayCurrentMonth) ? 'black' :
//       !isToday(day) && !isSameMonth(day, firstDayCurrentMonth) ? '#bfbfbf' :
//       'inherit' 
//   }}>

                           
//                             {format(day, 'd')}
//                           </div>
//                         <div className="daybookingcalendarcellcircle" />
//                         </div>
//                       </div>
//   ))
// }

                    


//                     </div>
//                   </div>
//                   <div className="coachavailabilitydetailsside">
//                     <div className="coachavailabilitydetailssidehe">
//                       <div className="coachavailabilitydetailssidehe1">
//                         <div className="thursday-december-8">
                        
//                          {
                          
//                           format(selectedDay, 'eeee, MMMM d')
//                          } 
//                         </div>
//                       </div>
//                       <div className="coachavailabilitydetailstimesl">
//                         <div className="coachavailabilitydetailstieslo">
//                           <div className="ellipsetimeslot" />
//                           <div className="timeslots-available">
//                             Timeslots Available
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="coachavailabilityfreetimes">
//                       <div className="coachavailabilityfreetimes1">
//                         <div className="coachavailabilityfreetimes2">
//                           <div className="coachavailabilityfreetimes3">
//                             <div className="ellipsetimeslot" />
//                             <b className="am">10:00 AM</b>
//                           </div>
//                         </div>
//                         <div className="coachavailabilityfreetimes2">
//                           <div className="coachavailabilityfreetimes3">
//                             <div className="ellipsetimeslot" />
//                             <b className="am">10:00 AM</b>
//                           </div>
//                         </div>
//                       </div>
//                       <div className="coachavailabilityfreetimes1">
//                         <div className="coachavailabilityfreetimes2">
//                           <div className="coachavailabilityfreetimes3">
//                             <div className="ellipsetimeslot" />
//                             <b className="am">10:00 AM</b>
//                           </div>
//                         </div>
//                         <div className="coachavailabilityfreetimes2">
//                           <div className="coachavailabilityfreetimes3">
//                             <div className="ellipsetimeslot" />
//                             <b className="am">10:00 AM</b>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="coachavailabilitytimezone">
//             <div className="coachavailabilitytimezoneside">
//               <div className="time-zone">Time zone</div>
//               <div className="coachavailabilitytimezonebotto">
//                 <div className="iconoirglobe">
//                 <svg className="group-icon" xmlns="http://www.w3.org/2000/svg" width="30" height="29" viewBox="0 0 30 29" fill="none">
//    <path d="M14.9585 26.3965C21.4316 26.3965 26.6787 21.1494 26.6787 14.6763C26.6787 8.2032 21.4316 2.95605 14.9585 2.95605C8.48543 2.95605 3.23828 8.2032 3.23828 14.6763C3.23828 21.1494 8.48543 26.3965 14.9585 26.3965Z" stroke="#787878" stroke-width="1.75803" stroke-linecap="round" stroke-linejoin="round"/>
//    <path d="M3.82422 15.2621L10.2703 17.6062L9.09832 21.7083L10.2703 25.2243M20.8185 24.6383L20.2325 21.7083L17.3025 20.5362V16.4342L20.8185 15.2621L26.0927 15.8482M23.1626 7.05799L22.5766 8.81602L18.4745 9.40203V12.9181L21.4046 11.7461H23.7486L26.0927 12.9181M3.82422 12.9181L6.75428 10.5741L9.68433 9.98804L12.0284 6.47198L10.8564 4.12793" stroke="#787878" stroke-width="1.75803" stroke-linecap="round" stroke-linejoin="round"/>
//  </svg>
//                 </div>
//                 <div className="central-time-">{`Central Time - US & Canada (8:07pm)`}</div>
//                 <svg xmlns="http://www.w3.org/2000/svg"  className="icon-park-outlinedown" width="27" height="25" viewBox="0 0 27 25" fill="none">
//    <path d="M20.1149 9.74658L13.6688 15.6067L7.22266 9.74658" stroke="#787878" stroke-width="2.34405" stroke-linecap="round" stroke-linejoin="round"/>
//  </svg>
//               </div>
//             </div>
//             <button
//               className="coachavailabilitytimezonebookn"
//               onClick={openBookCoachPopup}
//             >
//               <b className="coachavailabilitytimezonebookt">Book Now</b>
//             </button>
//           </div>
//         </div>
//       </div>
//       {isBookCoachPopupOpen && (
//         <PortalPopup
//           overlayColor="rgba(113, 113, 113, 0.3)"
//           placement="Centered"
//           onOutsideClick={closeBookCoachPopup}
//         >
//           <BookCoach onClose={closeBookCoachPopup} />
//         </PortalPopup>
//       )}
//     </>
<div >
      <InlineWidget url="https://calendly.com/mismailaabba/scholarship-sessions" />
      
    </div>
  );
};
export default CoachAvailabilityCalendar;
