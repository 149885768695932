import React, { useEffect, useState } from "react";
import "./ViewProfile.css";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import axios from "axios";

import debounce from "lodash.debounce";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import {
  Organizations,
  Religion,
  allCitizenships,
  formattedMajorsArray,
  fraternitiesArray,
  gpa,
  honorSocietiesArray,
  sororitiesArray,
  sportsArray,
} from "../Data/data";
import { BASE_Server_URL } from "../requestMethods";

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  border: `2px solid ${theme.palette.background.paper}`,
}));
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));
const ViewProfile = () => {
  const [activeTab, setActiveTab] = useState("personal");

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const [profile, setProfile] = useState({});
  //loading
  //error
  const viewUserProfile = () => {
    try {
      const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
      const currentUser = user && JSON.parse(user).currentUser;
      const TOKEN = currentUser?.tokens.access.token;

      const client = axios.create({
        baseURL: BASE_Server_URL,
        headers: { Authorization: `Bearer ${TOKEN}` },
      });

      const res = client.get("/profile");
      return res;
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    viewUserProfile()
      .then((result) => {
        setProfile(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    console.log(profile);
  }, [profile]);

  // const submitData = async() =>{
  //     try {
  //       const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
  //       const currentUser = user && JSON.parse(user).currentUser;
  //       const TOKEN = currentUser?.tokens.access.token;

  //       const client = axios.create({
  //         baseURL: "http://localhost:3000/v1/",
  //         headers: { Authorization: `Bearer ${TOKEN}` },
  //     });

  //       const res = await client.put("/profile", {

  //             "stateOfResidence": inputValueStateResidence == "" ? profile.stateOfResidence : inputValueStateResidence,
  //             "firstGeneration": inputValueFirstGeneration == "" ? profile.stateOfResidence : inputValueStateResidence,,
  //             "citizenshipStatus": "american",
  //             "disability": "none",
  //             "ethnicity": "african",
  //             "religion": "islam",
  //             "personalAttributes": "blue eyes",
  //             "militaryAffliation": "",
  //             "highSchool": "Capital Science Academy",
  //             "highSchoolGraduationYear": "",
  //             "collegeGraduationYear": "2022",
  //             "math": null,
  //             "mbrw": null,
  //             "actScore": 24,
  //             "ged": 400,
  //             "gpa": 3,
  //             "classLevel": "Undergraduate",
  //             "fieldOfStudy": "Computer  Engineering",
  //             "college": "Bellerbys College",
  //             "studentOrganizations": "",
  //             "honorSocieties": "honor 1",
  //             "fraternity": "Fraternity 3",
  //             "sorority": "Sumis College sorority",
  //             "sports": "tennis",
  //             "perfomingArts": "dance",
  //             "profileCompleted": true,
  //             "Math": null,
  //             "Ebrw": null,
  //             "owner": "64e200c86caf683064fd3d4c",
  //             "name": "Muhammad Ismaila",
  //             "email": "muhammad@gmail.com",
  //             "birthDate": "1996-02-13",
  //             "id": "64e200ce6caf683064fd3d55"

  //       })
  //       return(res)
  //       //dispatch(updateProfileSuccess(res.data))

  //   } catch (error) {
  //      console.log(error)
  //       //dispatch(updateProfileFailure());
  //   }
  //   }

  const saveChanges = async (key, data, dataF, previous) => {
    if (data && previous.toLowerCase() !== data.toLowerCase()) {
      const val = { [key]: data }; // Use square brackets to set the property name based on the key parameter

      try {
        const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
        const currentUser = user && JSON.parse(user).currentUser;
        const TOKEN = currentUser?.tokens.access.token;

        const client = axios.create({
          baseURL: BASE_Server_URL,
          headers: { Authorization: `Bearer ${TOKEN}` },
        });

        const res = await client.put("/profile", val);

        viewUserProfile()
          .then((result) => {
            setProfile(result.data);
          })
          .catch((error) => {
            console.log(error);
          });
        dataF(false);

        return res;
        //dispatch(updateProfileSuccess(res.data))
      } catch (error) {
        console.log(error);
        //dispatch(updateProfileFailure());
      }
    } else {
      console.log("no chages");
    }
  };
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleUpload = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("image", selectedFile);

      //   try {
      //     const response = await fetch('/api/upload', {
      //       method: 'POST',
      //       body: formData,
      //     });

      //     if (response.ok) {
      //       console.log('File uploaded successfully.');
      //     } else {
      //       console.error('Error uploading file.');
      //     }
      //   } catch (error) {
      //     console.error('Error uploading file:', error);
      //   }

      try {
        const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
        const currentUser = user && JSON.parse(user).currentUser;
        const TOKEN = currentUser?.tokens.access.token;

        const client = axios.create({
          baseURL: BASE_Server_URL,
          headers: { Authorization: `Bearer ${TOKEN}` },
        });

        const res = await client.post("/profile/upload", formData);
        return res;
        //dispatch(updateProfileSuccess(res.data))
      } catch (error) {
        console.log("error", error);
        //dispatch(updateProfileFailure());
      }
    }
  };

  const [isEditingBirthday, setIsEditingBirthday] = useState(false);
  const [inputValueBirthday, setInputValueBirthday] = useState(
    profile.birthDate
  );

  const handleEditClickBirthday = () => {
    setIsEditingBirthday(!isEditingBirthday);
  };

  const handleInputChangeBirthday = (event) => {
    setInputValueBirthday(event.target.value);
  };

  const [isEditingStateResidence, setIsEditingStateResidence] = useState(false);
  const [inputValueStateResidence, setInputValueStateResidence] = useState("");
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState([
    "Option 1",
    "Option 2",
    "Option 3",
  ]); // Replace with your actual options

  const handleEditClickStateResidence = () => {
    setIsEditingStateResidence(!isEditingStateResidence);
  };

  const handleInputChangeStateResidence = (event) => {
    setInputValueStateResidence(event.target.value);
  };

  const handleInputClick = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const handleOptionClick = (option) => {
    setInputValueStateResidence(option);
    setDropdownVisible(false);
  };

  const [isEditingFirstGeneration, setIsEditingFirstGeneration] =
    useState(false);
  const [inputValueFirstGeneration, setInputValueFirstGeneration] = useState(
    profile.firstGeneration
  );

  const handleEditClickFirstGeneration = () => {
    setIsEditingFirstGeneration(!isEditingFirstGeneration);
  };

  const handleInputChangeFirstGeneration = (event) => {
    setInputValueFirstGeneration(event.target.value);
  };

  useEffect(() => {
    //console.log("first geeratuin", inputValueFirstGeneration);
  }, []);

  const [isEditingCitizenship, setIsEditingCitizenship] = useState(false);
  const [inputValueCitizenship, setInputValueCitizenship] = useState("");

  const handleEditClickCitizenship = () => {
    setIsEditingCitizenship(!isEditingCitizenship);
  };

  const handleInputChangeCitizenship = (event) => {
    setInputValueCitizenship(event.target.value);
  };

  const [isEditingDisability, setIsEditingDisability] = useState(false);
  const [inputValueDisability, setInputValueDisability] = useState("");

  const handleEditClickDisability = () => {
    setIsEditingDisability(!isEditingDisability);
  };

  const handleInputChangeDisability = (event) => {
    setInputValueDisability(event.target.value);
  };

  const [isEditingEthnicity, setIsEditingEthnicity] = useState(false);
  const [inputValueEthnicity, setInputValueEthnicity] = useState("");

  const handleEditClickEthnicity = () => {
    setIsEditingEthnicity(!isEditingEthnicity);
  };

  const handleInputChangeEthnicity = (event) => {
    setInputValueEthnicity(event.target.value);
  };

  const [isEditingPersonalAttribute, setIsEditingPersonalAttribute] =
    useState(false);
  const [inputValuePersonalAttribute, setInputValuePersonalAttribute] =
    useState("");

  const handleEditClickPersonalAttribute = () => {
    setIsEditingPersonalAttribute(!isEditingPersonalAttribute);
  };

  const handleInputChangePersonalAttribute = (event) => {
    setInputValuePersonalAttribute(event.target.value);
  };

  const [isEditingMilitaryAffiliation, setIsEditingMilitaryAffiliation] =
    useState(false);
  const [inputValueMilitaryAffiliation, setInputValueMilitaryAffiliation] =
    useState("");

  const handleEditClickMilitaryAffiliation = () => {
    setIsEditingMilitaryAffiliation(!isEditingMilitaryAffiliation);
  };

  const handleInputChangeMilitaryAffiliation = (event) => {
    setInputValueMilitaryAffiliation(event.target.value);
  };

  const [isEditingReligion, setIsEditingReligion] = useState(false);
  const [inputValueReligion, setInputValueReligion] = useState("");

  const handleEditClickReligion = () => {
    setIsEditingReligion(!isEditingReligion);
  };

  const handleInputChangeReligion = (event) => {
    setInputValueReligion(event.target.value);
  };

  const [isEditingHighSchool, setIsEditingHighSchool] = useState(false);
  const [inputValueHighSchool, setInputValueHighSchool] = useState("");

  const handleEditClickHighSchool = () => {
    setIsEditingHighSchool(!isEditingHighSchool);
  };

  const handleInputChangeHighSchool = (event) => {
    setInputValueHighSchool(event.target.value);
  };

  const [isEditingHighSchoolGradYear, setIsEditingHighSchoolGradYear] =
    useState(false);
  const [inputValueHighSchoolGradYear, setInputValueHighSchoolGradYear] =
    useState("");

  const handleEditClickHighSchoolGradYear = () => {
    setIsEditingHighSchoolGradYear(!isEditingHighSchoolGradYear);
  };

  const handleInputChangeHighSchoolGradYear = (event) => {
    setInputValueHighSchoolGradYear(event.target.value);
  };

  const [isEditingCollegeGradYear, setIsEditingCollegeGradYear] =
    useState(false);
  const [inputValueCollegeGradYear, setInputValueCollegeGradYear] =
    useState("");

  const handleEditClickCollegeGradYear = () => {
    setIsEditingCollegeGradYear(!isEditingCollegeGradYear);
  };

  const handleInputChangeCollegeGradYear = (event) => {
    setInputValueCollegeGradYear(event.target.value);
  };

  const [isEditingAcademicLevel, setIsEditingAcademicLevel] = useState(false);
  const [inputValueAcademicLevel, setInputValueAcademicLevel] = useState("");

  const handleEditClickAcademicLevel = () => {
    setIsEditingAcademicLevel(!isEditingAcademicLevel);
  };

  const handleInputChangeAcademicLevel = (event) => {
    setInputValueAcademicLevel(event.target.value);
  };

  const [isEditingSatScore, setIsEditingSatScore] = useState(false);
  const [inputValueSatScore, setInputValueSatScore] = useState("");

  const handleEditClickSatScore = () => {
    setIsEditingSatScore(!isEditingSatScore);
  };

  const handleInputChangeSatScore = (event) => {
    setInputValueSatScore(event.target.value);
  };

  const [isEditingActScore, setIsEditingActScore] = useState(false);
  const [inputValueActScore, setInputValueActScore] = useState("");

  const handleEditClickActScore = () => {
    setIsEditingActScore(!isEditingActScore);
  };

  const handleInputChangeActScore = (event) => {
    setInputValueActScore(event.target.value);
  };

  const [isEditingGedScore, setIsEditingGedScore] = useState(false);
  const [inputValueGedScore, setInputValueGedScore] = useState("");

  const handleEditClickGedScore = () => {
    setIsEditingGedScore(!isEditingGedScore);
  };

  const handleInputChangeGedScore = (event) => {
    setInputValueGedScore(event.target.value);
  };

  const [isEditingGpa, setIsEditingGpa] = useState(false);
  const [inputValueGpa, setInputValueGpa] = useState("");

  const handleEditClickGpa = () => {
    setIsEditingGpa(!isEditingGpa);
  };

  const handleInputChangeGpa = (event) => {
    setInputValueGpa(event.target.value);
  };

  const [isEditingAcademicHonors, setIsEditingAcademicHonors] = useState(false);
  const [inputValueAcademicHonors, setInputValueAcademicHonors] = useState("");

  const handleEditClickAcademicHonors = () => {
    setIsEditingAcademicHonors(!isEditingAcademicHonors);
  };

  const handleInputChangeAcademicHonors = (event) => {
    setInputValueAcademicHonors(event.target.value);
  };

  const [isEditingFieldOfStudy, setIsEditingFieldOfStudy] = useState(false);
  const [inputValueFieldOfStudy, setInputValueFieldOfStudy] = useState("");

  const handleEditClickFieldOfStudy = () => {
    setIsEditingFieldOfStudy(!isEditingFieldOfStudy);
  };

  const handleInputChangeFieldOfStudy = (event) => {
    setInputValueFieldOfStudy(event.target.value);
  };

  const [isEditingCollege, setIsEditingCollege] = useState(false);
  const [inputValueCollege, setInputValueCollege] = useState("");

  const handleEditClickCollege = () => {
    setIsEditingCollege(!isEditingCollege);
  };

  const handleInputChangeCollege = (event) => {
    setInputValueCollege(event.target.value);
  };

  const [isEditingStudentOrganization, setIsEditingStudentOrganization] =
    useState(false);
  const [inputValueStudentOrganization, setInputValueStudentOrganization] =
    useState("");

  const handleEditClickStudentOrganization = () => {
    setIsEditingStudentOrganization(!isEditingStudentOrganization);
  };

  const handleInputChangeStudentOrganization = (event) => {
    setInputValueStudentOrganization(event.target.value);
  };

  const [isEditingHonorSocieties, setIsEditingHonorSocieties] = useState(false);
  const [inputValueHonorSocieties, setInputValueHonorSocieties] = useState("");

  const handleEditClickHonorSocieties = () => {
    setIsEditingHonorSocieties(!isEditingHonorSocieties);
  };

  const handleInputChangeHonorSocieties = (event) => {
    setInputValueHonorSocieties(event.target.value);
  };

  const [isEditingFraternity, setIsEditingFraternity] = useState(false);
  const [inputValueFraternity, setInputValueFraternity] = useState("");

  const handleEditClickFraternity = () => {
    setIsEditingFraternity(!isEditingFraternity);
  };

  const handleInputChangeFraternity = (event) => {
    setInputValueFraternity(event.target.value);
  };

  const [isEditingSorority, setIsEditingSorority] = useState(false);
  const [inputValueSorority, setInputValueSorority] = useState("");

  const handleEditClickSorority = () => {
    setIsEditingSorority(!isEditingSorority);
  };

  const handleInputChangeSorority = (event) => {
    setInputValueSorority(event.target.value);
  };

  const [isEditingSports, setIsEditingSports] = useState(false);
  const [inputValueSports, setInputValueSports] = useState("");

  const handleEditClickSports = () => {
    setIsEditingSports(!isEditingSports);
  };

  const handleInputChangeSports = (event) => {
    setInputValueSports(event.target.value);
  };

  const [isEditingPerfomingArts, setIsEditingPerfomingArts] = useState(false);
  const [inputValuePerfomingArts, setInputValuePerfomingArts] = useState("");

  const handleEditClickPerfomingArts = () => {
    setIsEditingPerfomingArts(!isEditingPerfomingArts);
  };

  const handleInputChangePerfomingArts = (event) => {
    setInputValuePerfomingArts(event.target.value);
  };

  const [isEditingBio, setIsEditingBio] = useState(false);
  const [inputValueBio, setInputValueBio] = useState("");

  const handleInputChangeBio = (event) => {
    setInputValueBio(event.target.value);
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([
    "Option 1",
    "Option 2",
    "Option 3",
  ]);
  const [anchorEl, setAnchorEl] = useState(null);
  //const [isEditingStateResidence, setIsEditingStateResidence] = useState(true); // Set to the appropriate initial value
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // const handleInputChange = (event) => {
  //   setSearchTerm(event.target.value);

  //   setAnchorEl(event.currentTarget);

  //   //setAnchorEl(event.currentTarget);
  // };

  // function convertDateFormat(originalDateString) {
  //   const [year, month, day] = originalDateString.split('-');
  //   const formattedDate = `${day}/${month}/${year}`;
  //   return formattedDate;
  // }
  const debouncedHandleInputChange = debounce((e) => {
    setSearchTerm(e.target.value);
    // Set anchor element after user finishes typing
    setAnchorEl(e.currentTarget);
  }, 300); // Adjust the delay as needed

  // const handleInputChange = (event) => {
  //   //const { value } = event.target;
  //   debouncedHandleInputChange(event);
  // };

  const debouncedSetAnchorEl = debounce((target) => {
    setAnchorEl(target);
  }, 700); // Adjust the delay as needed

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
    debouncedSetAnchorEl(event.currentTarget);
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchTerm(suggestion);
    //setAnchorEl(null);
    // Add any additional logic here
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const PROXY_URL = `${BASE_Server_URL}proxy`;

  const debouncedFetchSuggestions = debounce(async (inputValue) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `${PROXY_URL}/?input=${inputValue}&types=(cities)&types=(regions)`
      );
      const { predictions } = response.data;
      const locationSuggestions = predictions.map(
        (prediction) => prediction.description
      );

      setSuggestions(locationSuggestions);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching location suggestions:", error);
      setError("Error fetching suggestions. Please try again later.");
      setLoading(false);
    }
  }, 300);

  useEffect(() => {
    // Debounce the API call to avoid excessive requests
    debouncedFetchSuggestions(searchTerm);
  }, [searchTerm]);

  function convertDateFormat(originalDateString) {
    const [year, month, day] = originalDateString.split("-");
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  }

  function calculateAge(birthdate) {
    const today = new Date();
    const birthDate = new Date(birthdate);
    let age = today.getFullYear() - birthDate.getFullYear();

    // Check if birthday has occurred this year
    const hasBirthdayOccurred =
      today.getMonth() > birthDate.getMonth() ||
      (today.getMonth() === birthDate.getMonth() &&
        today.getDate() >= birthDate.getDate());

    if (!hasBirthdayOccurred) {
      age--;
    }

    return age;
  }

  const [isUploadingPic, setIsUploadingPic] = useState(false);
  const [image, setImage] = useState(null);

  const onInputChange = (e) => {
    console.log(e.target.files[0]);
    setImage(e.target.files[0]);
  };

  useEffect(() => {
    // Fetch profile data from the backend
    fetch("http://localhost:3000/v1/files") // Adjust the URL as necessary
      .then((response) => response.json())
      .then((data) => {
        console.log("Fetched profile data:", data); // Log the fetched data
        setProfile(data);
        setIsLoading(false);
      })
      .catch((error) => console.error("Error fetching profile:", error));
  }, []);

  const submitImage = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("profilePic", image);

    try {
      const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
      const currentUser = user && JSON.parse(user).currentUser;
      const TOKEN = currentUser?.tokens.access.token;

      const client = axios.create({
        baseURL: BASE_Server_URL,
        headers: { Authorization: `Bearer ${TOKEN}` },
      });

      const res = await client.put("/upload", formData);

      viewUserProfile()
        .then((result) => {
          setProfile(result.data);
        })
        .catch((error) => {
          console.log(error);
        });

      setIsUploadingPic(false);
      return res;
      //dispatch(updateProfileSuccess(res.data))
    } catch (error) {
      console.log(error);
      //dispatch(updateProfileFailure());
    }
  };
  console.log("Profile Picture URL:", profile.profilePic);

  return (
    <div className="viewprofile">
      <div className="profile">Profile</div>
      <div className="profile-section-parent">
        <div className="profile-section">
          <div className="profile-update">
            {/* <img className="upload-icon" alt="" src="/upload.svg" /> */}
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={
                //   <SmallAvatar alt="Remy Sharp" src="/upload.svg"/>
                <div className="upload-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    onClick={() => setIsUploadingPic(true)}
                  >
                    <path
                      d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
                      stroke="#B2B1B1"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.5 10C9.32843 10 10 9.32843 10 8.5C10 7.67157 9.32843 7 8.5 7C7.67157 7 7 7.67157 7 8.5C7 9.32843 7.67157 10 8.5 10Z"
                      stroke="#B2B1B1"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M21 15L16 10L5 21"
                      stroke="#B2B1B1"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              }
            >
              <Avatar
                sx={{ width: 104, height: 104 }}
                alt={profile.name ? profile.name.charAt(0) : ""}
                /* src="https://i.imgur.com/QpTeP2S.jpg" */
                src={
                  profile.profilePic
                    ? `http://localhost:3000/v1/files/${profile.profilePic}`
                    : ""
                }
              />
              {/* <img src ={ `http://localhost:3000/v1/profile/upload/uploads/1692622973790-231054894.jpg`} /> */}
            </Badge>

            {isUploadingPic ? (
              <div className="upload-image-button">
                <form
                  /* onSubmit={submitImage} */ className="upload-image-form"
                >
                  <input
                    type="file"
                    accept="image/*"
                    className="upload-choose-file"
                    onChange={onInputChange}
                  ></input>
                  <button
                    type="submit"
                    className="upload-choose-file-button"
                    onClick={(e) => submitImage(e)}
                  >
                    Submit
                  </button>
                </form>
              </div>
            ) : (
              <div></div>
            )}

            <div className="abidemi-awojuyigbe">{profile.name}</div>

            {/* <div>
        <input type="file" accept="image/*" onChange={handleFileChange} />
      <button onClick={handleUpload}>Upload</button>

      </div> */}
          </div>

          {/* <div className="bio-data">
          <div className="bio-data-box">
            <div className="bio-data-box-child" />
            <div className="biobox1">
              <p>Bio</p>
              <p>
                Striving for academic excellence
              </p>
              <div className="biobox1-child" />
            </div>
            <div className="emailbox">
              <p class>Email</p>
              <p className="abidemiawojuyigbegmailcom">{`abidemiawojuyigbe@gmail.com `}</p>
              <div className="emailbox-child" />
            </div>
            <div className="genderbox">
              <p className="email">Gender</p>
              <p className="abidemiawojuyigbegmailcom">Female</p>
              <div className="emailbox-child" />
            </div>
            <div className="age">
              <p className="email">Age</p>
              <p className="abidemiawojuyigbegmailcom">24</p>
              <div className="emailbox-child" />
            </div>
          </div>
        </div> */}

          <div className="bio-data">
            <div className="bio-data-box">
              <div />

              <p className="bio-detail-header">Bio</p>
              {isEditingBio ? (
                <textarea
                  className="bio-detail"
                  placeholder={profile.bio}
                  value={inputValueBio}
                  onChange={handleInputChangeBio}
                  disabled={!isEditingBio}
                  type="text"
                />
              ) : (
                <p className="bio-detail"> {profile.bio}</p>
              )}

              <div className="bio-line" />
              <div />

              <p className="bio-detail-header">Email</p>
              <p className="bio-detail">{profile.email}</p>
              <div className="bio-line" />

              <p className="bio-detail-header">Gender</p>
              <p className="bio-detail">{profile.gender}</p>
              <div className="bio-line" />

              <p className="bio-detail-header">Age</p>
              {profile.birthDate ? (
                <p className="bio-detail">{calculateAge(profile.birthDate)}</p>
              ) : (
                <p className="bio-detail"></p>
              )}

              <div className="bio-line" />
              <div />
            </div>
          </div>

          <div className="editbox">
            {isEditingBio ? (
              <a
                className="edit"
                name="bio"
                onClick={(e) => {
                  saveChanges(
                    e.target.name,
                    inputValueBio,
                    setIsEditingBio,
                    profile.bio
                  );
                }}
              >
                Save
              </a>
            ) : (
              <div className="edit" onClick={() => setIsEditingBio(true)}>
                Edit
              </div>
            )}
          </div>

          <div className="referallbox">
            <div className="referral-link-click-container">
              <p className="referral-link">Referral link</p>
              <p className="click-to-invite">Click to invite friends</p>
            </div>
          </div>
        </div>
        <div className="edit-data-box">
          <div className="edit-data-box-child" />
          <div className="profileeditbox">
            {/* <div className="selectedheaderprofile">
            <p className="personal-information">Personal Information</p>
            <div className="selectedheaderprofile-child" />
          </div> */}
            <p
              className={`personal-information ${
                activeTab === "personal" ? "profile-active-tab" : ""
              }`}
              onClick={() => handleTabClick("personal")}
            >
              Personal Information
            </p>
            <p
              className={`personal-information ${
                activeTab === "education" ? "profile-active-tab" : ""
              }`}
              onClick={() => handleTabClick("education")}
            >
              Education
            </p>
            <p
              className={`personal-information ${
                activeTab === "studentactivities" ? "profile-active-tab" : ""
              }`}
              onClick={() => handleTabClick("studentactivities")}
            >
              Student Activities
            </p>
          </div>

          {activeTab === "personal" ? (
            <div className="profile-edit-body">
              <div className="stateofresidencebox">
                <p className="state-of-residence">Birthday</p>
                <div className="birthday-box-child" />
                <div
                  className={
                    isEditingBirthday
                      ? "edit-details-box"
                      : "display-details-box"
                  }
                >
                  {/* <a>{convertDateFormat(inputValueBirthday)}</a> */}

                  {!isEditingBirthday ? (
                    <div className="yes">
                      {profile.birthDate ? (
                        <p>{convertDateFormat(String(profile.birthDate))}</p>
                      ) : (
                        <p></p>
                      )}
                    </div>
                  ) : (
                    <input
                      className={isEditingBirthday ? "t" : "yes"}
                      placeholder={profile.birthDate}
                      value={inputValueBirthday}
                      onChange={handleInputChangeBirthday}
                      disabled={!isEditingBirthday}
                      type="date"
                    />
                  )}

                  {
                    // onClick={handleEditClick}>{isEditing ? 'Cancel' : 'Edit'}
                    isEditingBirthday ? (
                      <a
                        className="save-changes"
                        name="birthDate"
                        onClick={(e) => {
                          saveChanges(
                            e.target.name,
                            String(inputValueBirthday),
                            setIsEditingBirthday,
                            String(profile.birthDate)
                          );
                        }}
                      >
                        Save Changes
                      </a>
                    ) : (
                      <div className="edit1" onClick={handleEditClickBirthday}>
                        Edit
                      </div>
                    )
                  }
                </div>
              </div>

              <div className="stateofresidencebox">
                <p className="state-of-residence">State of Residence</p>
                <div className="birthday-box-child" />
                <div
                  className={
                    isEditingStateResidence
                      ? "edit-details-box"
                      : "display-details-box"
                  }
                >
                  {isEditingStateResidence ? (
                    <input
                      className={isEditingStateResidence ? "t" : "yes"}
                      placeholder={profile.stateOfResidence}
                      /*   value={inputValueStateResidence}
              onChange={handleInputChangeStateResidence} */
                      disabled={!isEditingStateResidence}
                      value={searchTerm}
                      onChange={handleInputChange}
                    />
                  ) : (
                    <div className="yes">
                      <p>{profile.stateOfResidence}</p>
                    </div>
                  )}

                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                    PaperProps={{
                      style: {
                        width: 300,
                      },
                    }}
                  >
                    {suggestions.map((suggestion, index) => (
                      <MenuItem
                        key={index}
                        onClick={() => handleSuggestionClick(suggestion)}
                      >
                        {suggestion}
                      </MenuItem>
                    ))}
                  </Menu>
                  {
                    // onClick={handleEditClick}>{isEditing ? 'Cancel' : 'Edit'}
                    isEditingStateResidence ? (
                      <a
                        className="save-changes"
                        name="stateOfResidence"
                        onClick={(e) => {
                          saveChanges(
                            e.target.name,
                            searchTerm,
                            setIsEditingStateResidence,
                            profile.stateOfResidence
                          );
                        }}
                      >
                        Save Changes
                      </a>
                    ) : (
                      <div
                        className="edit1"
                        onClick={handleEditClickStateResidence}
                      >
                        Edit
                      </div>
                    )
                  }
                </div>
              </div>
              <div className="firstgenerationbox">
                <p className="state-of-residence">First Generation</p>
                <div className="birthday-box-child" />
                <div
                  className={
                    isEditingFirstGeneration
                      ? "edit-details-box"
                      : "display-details-box"
                  }
                >
                  {/* <p className="yes">Yes</p> */}
                  {/* <input className={isEditingFirstGeneration? "t": "yes"}
              placeholder={profile.firstGeneration}
              value={inputValueFirstGeneration}
              onChange={handleInputChangeFirstGeneration}
              disabled={!isEditingFirstGeneration}
            /> */}
                  {/* 
<Select
      className={isEditingFirstGeneration? "t": "yes"}
        labelId="first-generation-label"
        id="first-generation-select"
        value={inputValueFirstGeneration}
        onChange={handleInputChangeFirstGeneration}
        label="First Generation"
        disabled={!isEditingFirstGeneration}
      >
       
        <MenuItem value="yes">Yes</MenuItem>
        <MenuItem value="no">No</MenuItem> 
  
      </Select> */}

                  {isEditingFirstGeneration ? (
                    <select
                      className={isEditingFirstGeneration ? "t" : "yes"}
                      value={inputValueFirstGeneration}
                      onChange={handleInputChangeFirstGeneration}
                      disabled={!isEditingFirstGeneration}
                      defaultValue={inputValueFirstGeneration}
                    >
                      {/* Add your options here */}
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  ) : (
                    <div className="yes">
                      <p>{profile.firstGeneration}</p>
                    </div>
                  )}

                  {
                    // onClick={handleEditClick}>{isEditing ? 'Cancel' : 'Edit'}
                    isEditingFirstGeneration ? (
                      <a
                        className="save-changes"
                        name="firstGeneration"
                        onClick={(e) => {
                          saveChanges(
                            e.target.name,
                            inputValueFirstGeneration,
                            setIsEditingFirstGeneration,
                            profile.firstGeneration
                          );
                        }}
                      >
                        Save Changes
                      </a>
                    ) : (
                      <div
                        className="edit1"
                        onClick={handleEditClickFirstGeneration}
                      >
                        Edit
                      </div>
                    )
                  }
                </div>
              </div>

              <div className="citizenshipstatusbox">
                <p className="state-of-residence">Citizenship Status</p>
                <div className="birthday-box-child" />
                <div
                  className={
                    isEditingCitizenship
                      ? "edit-details-box"
                      : "display-details-box"
                  }
                >
                  {isEditingCitizenship ? (
                    <select
                      className={isEditingCitizenship ? "t" : "yes"}
                      placeholder={profile.citizenshipStatus}
                      value={inputValueCitizenship}
                      onChange={handleInputChangeCitizenship}
                      disabled={!isEditingCitizenship}
                    >
                      {/* Add your options here */}
                      {/* <option value="Yes">Yes</option>
            <option value="No">No</option> */}

                      {allCitizenships.map((org) => (
                        <option key={org.value} value={org.value}>
                          {org.name}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <div className="yes">
                      <p>{profile.citizenshipStatus}</p>
                    </div>
                  )}

                  {isEditingCitizenship ? (
                    <a
                      className="save-changes"
                      name="citizenshipStatus"
                      onClick={(e) => {
                        saveChanges(
                          e.target.name,
                          inputValueCitizenship,
                          setIsEditingCitizenship,
                          profile.citizenshipStatus
                        );
                      }}
                    >
                      Save Changes
                    </a>
                  ) : (
                    <div className="edit1" onClick={handleEditClickCitizenship}>
                      Edit
                    </div>
                  )}
                </div>
              </div>
              <div className="disabilitybox">
                <p className="state-of-residence">Disability</p>
                <div className="birthday-box-child" />
                <div
                  className={
                    isEditingDisability
                      ? "edit-details-box"
                      : "display-details-box"
                  }
                >
                  {isEditingDisability ? (
                    //   <input className={isEditingDisability? "t": "yes"}
                    //   placeholder={profile.disability}
                    //   value={inputValueDisability}
                    //   onChange={handleInputChangeDisability}
                    //   disabled={!isEditingDisability}

                    // />

                    <select
                      name="disability"
                      required="required"
                      className={isEditingDisability ? "t" : "yes"}
                      placeholder={profile.disability}
                      value={inputValueDisability}
                      onChange={handleInputChangeDisability}
                      disabled={!isEditingDisability}
                    >
                      <option value="">Select Disability</option>
                      <option value="Physical disability">
                        Physical disability
                      </option>
                      <option value="Visual impairment">
                        Visual impairment
                      </option>
                      <option value="Hearing impairment">
                        Hearing impairment
                      </option>
                      <option value="Cognitive disability">
                        Cognitive disability
                      </option>
                      <option value="Learning disability">
                        Learning disability
                      </option>
                      <option value="Mental health condition">
                        Mental health condition
                      </option>
                      <option value="Other">Other</option>
                      <option value="None">None</option>
                    </select>
                  ) : (
                    <div className="yes">
                      <p>{profile.disability}</p>
                    </div>
                  )}
                  {isEditingDisability ? (
                    <a
                      className="save-changes"
                      name="disability"
                      onClick={(e) => {
                        saveChanges(
                          e.target.name,
                          inputValueDisability,
                          setIsEditingDisability,
                          profile.disability
                        );
                      }}
                    >
                      Save Changes
                    </a>
                  ) : (
                    <div className="edit1" onClick={handleEditClickDisability}>
                      Edit
                    </div>
                  )}
                </div>
              </div>
              <div className="ethnicitybox">
                <p className="state-of-residence">{`Ethnicity `}</p>
                <div className="birthday-box-child" />
                <div
                  className={
                    isEditingEthnicity
                      ? "edit-details-box"
                      : "display-details-box"
                  }
                >
                  {/* <input className={
            isEditingEthnicity? "t": "yes"}
              placeholder={profile.ethnicity}
              value={inputValueEthnicity}
              onChange={handleInputChangeEthnicity}
              disabled={!isEditingEthnicity}
            /> */}

                  {isEditingEthnicity ? (
                    <select
                      //className="editprofileformboxinputbox19"
                      name="ethnicity"
                      required="required"
                      className={isEditingEthnicity ? "t" : "yes"}
                      placeholder={profile.ethnicity}
                      value={inputValueEthnicity}
                      onChange={handleInputChangeEthnicity}
                      disabled={!isEditingEthnicity}
                    >
                      <option value="">Select Ethnicity</option>
                      <option value="Asian">Asian</option>
                      <option value="Black or African American">
                        Black or African American
                      </option>
                      <option value="Hispanic or latino">
                        Hispanic or latino
                      </option>
                      <option value="Native American/American Indian or Alaskan Native">
                        Native American/American Indian or Alaskan Native
                      </option>
                      <option value="Pacific Islander, including Native Hawaiian">
                        Pacific Islander, including Native Hawaiian
                      </option>
                      <option value="White or Caucasian">
                        White or Caucasian
                      </option>
                      <option value="Prefer not to say">
                        Prefer not to say
                      </option>
                    </select>
                  ) : (
                    <div className="yes">
                      <p>{profile.ethnicity}</p>
                    </div>
                  )}
                  {isEditingEthnicity ? (
                    <a
                      className="save-changes"
                      name="ethnicity"
                      onClick={(e) => {
                        saveChanges(
                          e.target.name,
                          inputValueEthnicity,
                          setIsEditingEthnicity,
                          profile.ethnicity
                        );
                      }}
                    >
                      Save Changes
                    </a>
                  ) : (
                    <div className="edit1" onClick={handleEditClickEthnicity}>
                      Edit
                    </div>
                  )}
                </div>
              </div>

              <div className="personalattributebox">
                <p className="state-of-residence">{`Religion `}</p>
                <div className="birthday-box-child" />
                <div
                  className={
                    isEditingReligion
                      ? "edit-details-box"
                      : "display-details-box"
                  }
                >
                  {isEditingReligion ? (
                    <select
                      className={isEditingReligion ? "t" : "yes"}
                      placeholder={profile.religion}
                      value={inputValueReligion}
                      onChange={handleInputChangeReligion}
                      disabled={!isEditingReligion}
                      name="religion"
                      required="required"
                    >
                      {Religion.map((org) => (
                        <option key={org.name} value={org.value}>
                          {org.name}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <div className="yes">
                      <p>{profile.religion}</p>
                    </div>
                  )}
                  {isEditingReligion ? (
                    <a
                      className="save-changes"
                      name="religion"
                      onClick={(e) => {
                        saveChanges(
                          e.target.name,
                          inputValueReligion,
                          setIsEditingReligion,
                          profile.religion
                        );
                      }}
                    >
                      Save Changes
                    </a>
                  ) : (
                    <div className="edit1" onClick={handleEditClickReligion}>
                      Edit
                    </div>
                  )}
                </div>
              </div>
              <div className="personalattributebox">
                <p className="state-of-residence">{`Personal Attributes `}</p>
                <div className="birthday-box-child" />
                <div
                  className={
                    isEditingPersonalAttribute
                      ? "edit-details-box"
                      : "display-details-box"
                  }
                >
                  {isEditingPersonalAttribute ? (
                    <select
                      // required={true}
                      name="personalAttributes"
                      required="required"
                      className={isEditingPersonalAttribute ? "t" : "yes"}
                      placeholder={profile.personalAttributes}
                      value={inputValuePersonalAttribute}
                      onChange={handleInputChangePersonalAttribute}
                      disabled={!isEditingPersonalAttribute}
                    >
                      <option value="">Select Attribute</option>
                      <option value="First-generation college student">
                        First-generation college student
                      </option>
                      <option value="Veteran status">Veteran status</option>
                      <option value="Refugee or asylum seeker status">
                        Refugee or asylum seeker status
                      </option>
                      <option value="Single parent">Single parent</option>
                      <option value="Caregiver responsibilities">
                        Caregiver responsibilities
                      </option>
                      <option value="LGBTQ+">LGBTQ+</option>
                      <option value="Non traditional Student">
                        Non traditional Student
                      </option>
                      <option value="DACA (Deferred Action for Childhood Arrivals)">
                        DACA (Deferred Action for Childhood Arrivals)
                      </option>
                      <option value="Foster Care">Foster Care</option>
                      <option value="Orphan">Orphan</option>
                      <option value="Other">Other</option>
                    </select>
                  ) : (
                    <div className="yes">
                      <p>{profile.personalAttributes}</p>
                    </div>
                  )}
                  {isEditingPersonalAttribute ? (
                    <a
                      className="save-changes"
                      name="personalAttributes"
                      onClick={(e) => {
                        saveChanges(
                          e.target.name,
                          inputValuePersonalAttribute,
                          setIsEditingPersonalAttribute,
                          profile.personalAttributes
                        );
                      }}
                    >
                      Save Changes
                    </a>
                  ) : (
                    <div
                      className="edit1"
                      onClick={handleEditClickPersonalAttribute}
                    >
                      Edit
                    </div>
                  )}
                </div>
              </div>
              <div className="militaryaffilitionbox">
                <p className="state-of-residence">Military Affiliation</p>
                <div className="birthday-box-child" />
                <div
                  className={
                    isEditingMilitaryAffiliation
                      ? "edit-details-box"
                      : "display-details-box"
                  }
                >
                  {isEditingMilitaryAffiliation ? (
                    <select
                      className={isEditingMilitaryAffiliation ? "t" : "yes"}
                      value={inputValueMilitaryAffiliation}
                      onChange={handleInputChangeMilitaryAffiliation}
                      disabled={!isEditingMilitaryAffiliation}
                      defaultValue={inputValueMilitaryAffiliation}
                    >
                      {/* Add your options here */}
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  ) : (
                    <div className="yes">
                      <p>{profile.militaryAffliation}</p>
                    </div>
                  )}
                  {isEditingMilitaryAffiliation ? (
                    <a
                      className="save-changes"
                      name="militaryAffliation"
                      onClick={(e) => {
                        saveChanges(
                          e.target.name,
                          inputValueMilitaryAffiliation,
                          setIsEditingMilitaryAffiliation,
                          profile.militaryAffliation
                        );
                      }}
                    >
                      Save Changes
                    </a>
                  ) : (
                    <div
                      className="edit1"
                      onClick={handleEditClickMilitaryAffiliation}
                    >
                      Edit
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : null}

          {activeTab === "education" ? (
            <div className="profile-edit-body">
              <div className="stateofresidencebox">
                <p className="state-of-residence">High School</p>
                <div className="birthday-box-child" />
                <div
                  className={
                    isEditingHighSchool
                      ? "edit-details-box"
                      : "display-details-box"
                  }
                >
                  {isEditingHighSchool ? (
                    <input
                      className={isEditingHighSchool ? "t" : "yes"}
                      placeholder={profile.highSchool}
                      value={inputValueHighSchool}
                      onChange={handleInputChangeHighSchool}
                      disabled={!isEditingHighSchool}
                    />
                  ) : (
                    <div className="yes">
                      <p>{profile.highSchool}</p>
                    </div>
                  )}

                  {
                    // onClick={handleEditClick}>{isEditing ? 'Cancel' : 'Edit'}
                    isEditingHighSchool ? (
                      <a
                        className="save-changes"
                        name="highSchool"
                        onClick={(e) => {
                          saveChanges(
                            e.target.name,
                            String(inputValueHighSchool),
                            setIsEditingHighSchool,
                            String(profile.highSchool)
                          );
                        }}
                      >
                        Save Changes
                      </a>
                    ) : (
                      <div
                        className="edit1"
                        onClick={handleEditClickHighSchool}
                      >
                        Edit
                      </div>
                    )
                  }
                </div>
              </div>

              <div className="stateofresidencebox">
                <p className="state-of-residence">High School Grad Year</p>
                <div className="birthday-box-child" />

                <div
                  className={
                    isEditingHighSchoolGradYear
                      ? "edit-details-box"
                      : "display-details-box"
                  }
                >
                  {isEditingHighSchoolGradYear ? (
                    <select
                      //className="inputtextboxdateyear"
                      required="required"
                      // onChange={props.onChange}
                      name="highSchoolGraduationYear"
                      className={isEditingHighSchoolGradYear ? "t" : "yes"}
                      placeholder={profile.highSchoolGraduationYear}
                      value={inputValueHighSchoolGradYear}
                      onChange={handleInputChangeHighSchoolGradYear}
                      disabled={!isEditingHighSchoolGradYear}
                    >
                      <option value="">Select year</option>
                      {Array.from({ length: 108 }, (_, index) => {
                        const year = 1923 + index;
                        return (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        );
                      })}
                    </select>
                  ) : (
                    <div className="yes">
                      <p>{profile.highSchoolGraduationYear}</p>
                    </div>
                  )}

                  {
                    // onClick={handleEditClick}>{isEditing ? 'Cancel' : 'Edit'}
                    isEditingHighSchoolGradYear ? (
                      <a
                        className="save-changes"
                        name="highSchoolGraduationYear"
                        onClick={(e) => {
                          saveChanges(
                            e.target.name,
                            inputValueHighSchoolGradYear,
                            setIsEditingHighSchoolGradYear,
                            profile.highSchoolGraduationYear
                          );
                        }}
                      >
                        Save Changes
                      </a>
                    ) : (
                      <div
                        className="edit1"
                        onClick={handleEditClickHighSchoolGradYear}
                      >
                        Edit
                      </div>
                    )
                  }
                </div>
              </div>

              <div className="stateofresidencebox">
                <p className="state-of-residence">College Grad Year</p>
                <div className="birthday-box-child" />
                <div
                  className={
                    isEditingCollegeGradYear
                      ? "edit-details-box"
                      : "display-details-box"
                  }
                >
                  {/* 
                   <input className={isEditingCollegeGradYear? "t": "yes"}
                     placeholder={profile.collegeGraduationYear}
                     value={inputValueCollegeGradYear}
                     onChange={handleInputChangeCollegeGradYear}
                     disabled={!isEditingCollegeGradYear}
                   /> */}

                  {isEditingCollegeGradYear ? (
                    <select
                      //className="inputtextboxdateyear"
                      required="required"
                      // onChange={props.onChange}
                      name="collegeGraduationYear"
                      className={isEditingCollegeGradYear ? "t" : "yes"}
                      placeholder={profile.collegeGraduationYear}
                      value={inputValueCollegeGradYear}
                      onChange={handleInputChangeCollegeGradYear}
                      disabled={!isEditingCollegeGradYear}
                    >
                      <option value="">Select year</option>
                      {Array.from({ length: 108 }, (_, index) => {
                        const year = 1923 + index;
                        return (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        );
                      })}
                    </select>
                  ) : (
                    <div className="yes">
                      <p>{profile.collegeGraduationYear}</p>
                    </div>
                  )}
                  {
                    // onClick={handleEditClick}>{isEditing ? 'Cancel' : 'Edit'}
                    isEditingCollegeGradYear ? (
                      <a
                        className="save-changes"
                        name="collegeGraduationYear"
                        onClick={(e) => {
                          saveChanges(
                            e.target.name,
                            inputValueCollegeGradYear,
                            setIsEditingCollegeGradYear,
                            profile.collegeGraduationYear
                          );
                        }}
                      >
                        Save Changes
                      </a>
                    ) : (
                      <div
                        className="edit1"
                        onClick={handleEditClickCollegeGradYear}
                      >
                        Edit
                      </div>
                    )
                  }
                </div>
              </div>

              <div className="stateofresidencebox">
                <p className="state-of-residence">Academic Level</p>
                <div className="birthday-box-child" />
                <div
                  className={
                    isEditingAcademicLevel
                      ? "edit-details-box"
                      : "display-details-box"
                  }
                >
                  {/* <input className={isEditingAcademicLevel? "t": "yes"}
                     placeholder={profile.classLevel
                     }
                     value={inputValueAcademicLevel}
                     onChange={handleInputChangeAcademicLevel}
                     disabled={!isEditingAcademicLevel}
                   /> */}

                  {isEditingAcademicLevel ? (
                    <select
                      // className="editprofileformboxinputbox10"
                      name="classLevel"
                      required="required"
                      // onChange={props.onChange}
                      className={isEditingAcademicLevel ? "t" : "yes"}
                      placeholder={profile.classLevel}
                      value={inputValueAcademicLevel}
                      onChange={handleInputChangeAcademicLevel}
                      disabled={!isEditingAcademicLevel}
                    >
                      <option value="">Select Education Level</option>
                      <option value="High School">High School</option>
                      <option value="Undergraduate">
                        Bachelors/Undergraduate
                      </option>
                      <option value="Graduate">Masters/Graduate</option>
                      <option value="Phd">Phd</option>
                      <option value="Associate">Associate</option>
                      <option value="Trade School">Trade School</option>
                    </select>
                  ) : (
                    <div className="yes">
                      <p>{profile.classLevel}</p>
                    </div>
                  )}

                  {
                    // onClick={handleEditClick}>{isEditing ? 'Cancel' : 'Edit'}
                    isEditingAcademicLevel ? (
                      <a
                        className="save-changes"
                        name="classLevel"
                        onClick={(e) => {
                          saveChanges(
                            e.target.name,
                            inputValueAcademicLevel,
                            setIsEditingAcademicLevel,
                            profile.classLevel
                          );
                        }}
                      >
                        Save Changes
                      </a>
                    ) : (
                      <div
                        className="edit1"
                        onClick={handleEditClickAcademicLevel}
                      >
                        Edit
                      </div>
                    )
                  }
                </div>
              </div>

              <div className="stateofresidencebox">
                <p className="state-of-residence">Act Score (1-26)</p>
                <div className="birthday-box-child" />
                <div
                  className={
                    isEditingActScore
                      ? "edit-details-box"
                      : "display-details-box"
                  }
                >
                  {isEditingActScore ? (
                    <select
                      //className="inputtextboxdateyear"
                      required="required"
                      // onChange={props.onChange}
                      name="actScore"
                      className={isEditingActScore ? "t" : "yes"}
                      placeholder={profile.actScore}
                      value={inputValueActScore}
                      onChange={handleInputChangeActScore}
                      disabled={!isEditingActScore}
                    >
                      <option value="">Select Score</option>
                      {Array.from({ length: 26 }, (_, index) => {
                        const year = 1 + index;
                        return (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        );
                      })}
                    </select>
                  ) : (
                    <div className="yes">
                      <p>{profile.actScore}</p>
                    </div>
                  )}

                  {
                    // onClick={handleEditClick}>{isEditing ? 'Cancel' : 'Edit'}
                    isEditingActScore ? (
                      <a
                        className="save-changes"
                        name="actScore"
                        onClick={(e) => {
                          saveChanges(
                            e.target.name,
                            String(inputValueActScore),
                            setIsEditingActScore,
                            String(profile.actScore)
                          );
                        }}
                      >
                        Save Changes
                      </a>
                    ) : (
                      <div className="edit1" onClick={handleEditClickActScore}>
                        Edit
                      </div>
                    )
                  }
                </div>
              </div>

              <div className="stateofresidencebox">
                <p className="state-of-residence">GED Score (200-800)</p>
                <div className="birthday-box-child" />
                <div
                  className={
                    isEditingGedScore
                      ? "edit-details-box"
                      : "display-details-box"
                  }
                >
                  {isEditingGedScore ? (
                    <input
                      className={isEditingGedScore ? "t" : "yes"}
                      placeholder={profile.ged}
                      value={inputValueGedScore}
                      onChange={handleInputChangeGedScore}
                      disabled={!isEditingGedScore}
                    />
                  ) : (
                    <div className="yes">
                      <p>{profile.ged}</p>
                    </div>
                  )}

                  {
                    // onClick={handleEditClick}>{isEditing ? 'Cancel' : 'Edit'}
                    isEditingGedScore ? (
                      <a
                        className="save-changes"
                        name="ged"
                        onClick={(e) => {
                          saveChanges(
                            e.target.name,
                            String(inputValueGedScore),
                            setIsEditingGedScore,
                            String(profile.ged)
                          );
                        }}
                      >
                        Save Changes
                      </a>
                    ) : (
                      <div className="edit1" onClick={handleEditClickGedScore}>
                        Edit
                      </div>
                    )
                  }
                </div>
              </div>

              <div className="stateofresidencebox">
                <p className="state-of-residence">GPA (Overall)</p>
                <div className="birthday-box-child" />
                <div
                  className={
                    isEditingGpa ? "edit-details-box" : "display-details-box"
                  }
                >
                  {/* <input className={isEditingGpa? "t": "yes"}
                     placeholder={profile.gpa}
                     value={inputValueGpa}
                     onChange={handleInputChangeGpa}
                     disabled={!isEditingGpa}
                   /> */}

                  {isEditingGpa ? (
                    <select
                      required="required"
                      name="gpa"
                      className={isEditingGpa ? "t" : "yes"}
                      placeholder={profile.gpa}
                      value={inputValueGpa}
                      onChange={handleInputChangeGpa}
                      disabled={!isEditingGpa}
                    >
                      <option value="">Select GPA</option>
                      {gpa.map((org) => (
                        <option key={org} value={org}>
                          {org}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <div className="yes">
                      <p>{profile.gpa}</p>
                    </div>
                  )}

                  {
                    // onClick={handleEditClick}>{isEditing ? 'Cancel' : 'Edit'}
                    isEditingGpa ? (
                      <a
                        className="save-changes"
                        name="gpa"
                        onClick={(e) => {
                          saveChanges(
                            e.target.name,
                            inputValueGpa,
                            setIsEditingGpa,
                            profile.gpa
                          );
                        }}
                      >
                        Save Changes
                      </a>
                    ) : (
                      <div className="edit1" onClick={handleEditClickGpa}>
                        Edit
                      </div>
                    )
                  }
                </div>
              </div>

              {/* //edit scema
               <div className="birthday-box"  >
                 <p className="birthday">Academic Honors</p>
                 <div className="birthday-box-child" />
                 <div className={isEditingAcademicHonors? "edit-details-box": "display-details-box"}>
                   <input className={isEditingAcademicHonors? "t": "yes"}
                     placeholder='Honor Student'
                     value={inputValueAcademicHonors}
                     onChange={handleInputChangeAcademicHonors}
                     disabled={!isEditingAcademicHonors}
                   />
                  
               
             {
               // onClick={handleEditClick}>{isEditing ? 'Cancel' : 'Edit'}
               isEditingAcademicHonors ?  <div className="save-changes">Save Changes</div>:  <div className="edit1"  onClick={handleEditClickAcademicHonors}>Edit</div>
             }
                  
                 </div>
               </div> */}

              <div className="stateofresidencebox">
                <p className="state-of-residence">
                  Field Of Study (Current or Prospective)
                </p>
                <div className="birthday-box-child" />
                <div
                  className={
                    isEditingFieldOfStudy
                      ? "edit-details-box"
                      : "display-details-box"
                  }
                >
                  {isEditingFieldOfStudy ? (
                    <select
                      name="fieldOfStudy"
                      required="required"
                      className={isEditingFieldOfStudy ? "t" : "yes"}
                      placeholder={profile.fieldOfStudy}
                      value={inputValueFieldOfStudy}
                      onChange={handleInputChangeFieldOfStudy}
                      disabled={!isEditingFieldOfStudy}
                    >
                      {formattedMajorsArray.map((org) => (
                        <option key={org.name} value={org.value}>
                          {org.name}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <div className="yes">
                      <p>{profile.fieldOfStudy}</p>
                    </div>
                  )}

                  {
                    // onClick={handleEditClick}>{isEditing ? 'Cancel' : 'Edit'}
                    isEditingFieldOfStudy ? (
                      <a
                        className="save-changes"
                        name="fieldOfStudy"
                        onClick={(e) => {
                          saveChanges(
                            e.target.name,
                            inputValueFieldOfStudy,
                            setIsEditingFieldOfStudy,
                            profile.fieldOfStudy
                          );
                        }}
                      >
                        Save Changes
                      </a>
                    ) : (
                      <div
                        className="edit1"
                        onClick={handleEditClickFieldOfStudy}
                      >
                        Edit
                      </div>
                    )
                  }
                </div>
              </div>

              <div className="stateofresidencebox">
                <p className="state-of-residence">
                  College (Current or Prospective)
                </p>
                <div className="birthday-box-child" />
                <div
                  className={
                    isEditingCollege
                      ? "edit-details-box"
                      : "display-details-box"
                  }
                >
                  <input
                    className={isEditingCollege ? "t" : "yes"}
                    placeholder={profile.college}
                    value={inputValueCollege}
                    onChange={handleInputChangeCollege}
                    disabled={!isEditingCollege}
                  />

                  {
                    // onClick={handleEditClick}>{isEditing ? 'Cancel' : 'Edit'}
                    isEditingCollege ? (
                      <a
                        className="save-changes"
                        name="college"
                        onClick={(e) => {
                          saveChanges(e.target.name, inputValueCollege);
                        }}
                      >
                        Save Changes
                      </a>
                    ) : (
                      <div className="edit1" onClick={handleEditClickCollege}>
                        Edit
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          ) : null}
          {activeTab === "studentactivities" ? (
            <div className="profile-edit-body">
              <div className="stateofresidencebox">
                <p className="state-of-residence">Student Organizations </p>
                <div className="birthday-box-child" />
                <div
                  className={
                    isEditingStudentOrganization
                      ? "edit-details-box"
                      : "display-details-box"
                  }
                >
                  {isEditingStudentOrganization ? (
                    <select
                      name="studentOrganization"
                      required="required"
                      className={isEditingStudentOrganization ? "t" : "yes"}
                      placeholder={profile.studentOrganizations}
                      value={inputValueStudentOrganization}
                      onChange={handleInputChangeStudentOrganization}
                      disabled={!isEditingStudentOrganization}
                    >
                      {/* <option value="4-H Club">4-H Club</option>
              <option value="AACN">AACN</option> */}

                      {Organizations.map((org) => (
                        <option key={org.name} value={org.value}>
                          {org.name}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <div className="yes">
                      <p>{profile.studentOrganizations}</p>
                    </div>
                  )}

                  {
                    // onClick={handleEditClick}>{isEditing ? 'Cancel' : 'Edit'}
                    isEditingStudentOrganization ? (
                      <a
                        className="save-changes"
                        name="studentOrganizations"
                        onClick={(e) => {
                          saveChanges(
                            e.target.name,
                            inputValueStudentOrganization,
                            setIsEditingStudentOrganization,
                            profile.studentOrganizations
                          );
                        }}
                      >
                        Save Changes
                      </a>
                    ) : (
                      <div
                        className="edit1"
                        onClick={handleEditClickStudentOrganization}
                      >
                        Edit
                      </div>
                    )
                  }
                </div>
              </div>

              <div className="stateofresidencebox">
                <p className="state-of-residence">Honor Societies </p>
                <div className="birthday-box-child" />
                <div
                  className={
                    isEditingHonorSocieties
                      ? "edit-details-box"
                      : "display-details-box"
                  }
                >
                  {isEditingHonorSocieties ? (
                    <select
                      name="honorSocieties"
                      required="required"
                      className={isEditingHonorSocieties ? "t" : "yes"}
                      placeholder={profile.honorSocieties}
                      value={inputValueHonorSocieties}
                      onChange={handleInputChangeHonorSocieties}
                      disabled={!isEditingHonorSocieties}
                    >
                      {/* <option value="Alpha Beta Gamma">Alpha Beta Gamma</option>
              <option value="Alpha Chi">Alpha Chi</option>
              <option value="Alpha Chi Sigma’Alpha Delta Mu">
                Alpha Chi Sigma’Alpha Delta Mu
              </option> */}
                      {honorSocietiesArray.map((org) => (
                        <option key={org.name} value={org.value}>
                          {org.name}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <div className="yes">
                      <p>{profile.honorSocieties}</p>
                    </div>
                  )}

                  {
                    // onClick={handleEditClick}>{isEditing ? 'Cancel' : 'Edit'}
                    isEditingHonorSocieties ? (
                      <a
                        className="save-changes"
                        name="honorSocieties"
                        onClick={(e) => {
                          saveChanges(
                            e.target.name,
                            inputValueHonorSocieties,
                            setIsEditingHonorSocieties,
                            profile.honorSocieties
                          );
                        }}
                      >
                        Save Changes
                      </a>
                    ) : (
                      <div
                        className="edit1"
                        onClick={handleEditClickHonorSocieties}
                      >
                        Edit
                      </div>
                    )
                  }
                </div>
              </div>

              <div className="stateofresidencebox">
                <p className="state-of-residence">Fraternity </p>
                <div className="birthday-box-child" />
                <div
                  className={
                    isEditingFraternity
                      ? "edit-details-box"
                      : "display-details-box"
                  }
                >
                  {isEditingFraternity ? (
                    <select
                      name="fraternity"
                      required="required"
                      className={isEditingFraternity ? "t" : "yes"}
                      placeholder={profile.fraternity}
                      value={inputValueFraternity}
                      onChange={handleInputChangeFraternity}
                      disabled={!isEditingFraternity}
                    >
                      {/* <option value="Acacia">Acacia</option>
              <option value="Alpha chi rho">Alpha chi rho</option> */}

                      {fraternitiesArray.map((org) => (
                        <option key={org.name} value={org.value}>
                          {org.name}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <div className="yes">
                      <p>{profile.fraternity}</p>
                    </div>
                  )}

                  {
                    // onClick={handleEditClick}>{isEditing ? 'Cancel' : 'Edit'}
                    isEditingFraternity ? (
                      <a
                        className="save-changes"
                        name="fraternity"
                        onClick={(e) => {
                          saveChanges(
                            e.target.name,
                            inputValueFraternity,
                            setIsEditingFraternity,
                            profile.fraternity
                          );
                        }}
                      >
                        Save Changes
                      </a>
                    ) : (
                      <div
                        className="edit1"
                        onClick={handleEditClickFraternity}
                      >
                        Edit
                      </div>
                    )
                  }
                </div>
              </div>

              <div className="stateofresidencebox">
                <p className="state-of-residence">Sorority </p>
                <div className="birthday-box-child" />
                <div
                  className={
                    isEditingSorority
                      ? "edit-details-box"
                      : "display-details-box"
                  }
                >
                  {isEditingSorority ? (
                    <select
                      name="sorority"
                      required="required"
                      className={isEditingSorority ? "t" : "yes"}
                      placeholder={profile.sorority}
                      value={inputValueSorority}
                      onChange={handleInputChangeSorority}
                      disabled={!isEditingSorority}
                    >
                      {/* <option value="Acacia">Acacia</option>
              <option value="Alpha chi rho">Alpha chi rho</option> */}

                      {sororitiesArray.map((org) => (
                        <option key={org.name} value={org.value}>
                          {org.name}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <div className="yes">
                      <p>{profile.sorority}</p>
                    </div>
                  )}

                  {
                    // onClick={handleEditClick}>{isEditing ? 'Cancel' : 'Edit'}
                    isEditingSorority ? (
                      <a
                        className="save-changes"
                        name="sorority"
                        onClick={(e) => {
                          saveChanges(
                            e.target.name,
                            inputValueSorority,
                            setIsEditingSorority,
                            profile.sorority
                          );
                        }}
                      >
                        Save Changes
                      </a>
                    ) : (
                      <div className="edit1" onClick={handleEditClickSorority}>
                        Edit
                      </div>
                    )
                  }
                </div>
              </div>

              <div className="stateofresidencebox">
                <p className="state-of-residence">Sports</p>
                <div className="birthday-box-child" />
                <div
                  className={
                    isEditingSports ? "edit-details-box" : "display-details-box"
                  }
                >
                  {isEditingSports ? (
                    <select
                      name="sports"
                      required="required"
                      className={isEditingSports ? "t" : "yes"}
                      placeholder={profile.sports}
                      value={inputValueSports}
                      onChange={handleInputChangeSports}
                      disabled={!isEditingSports}
                    >
                      {/* <option value="Archery">Archery</option>
                 <option value="Athletics">Athletics</option>
                 <option value="Football">Football</option>
                 <option value="Basketball">Basketball</option>
                 <option value="Golf">Golf</option> */}
                      {sportsArray.map((org) => (
                        <option key={org.name} value={org.value}>
                          {org.name}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <div className="yes">
                      <p>{profile.sports}</p>
                    </div>
                  )}
                  {
                    // onClick={handleEditClick}>{isEditing ? 'Cancel' : 'Edit'}
                    isEditingSports ? (
                      <a
                        className="save-changes"
                        name="sports"
                        onClick={(e) => {
                          saveChanges(
                            e.target.name,
                            inputValueSports,
                            setIsEditingSports,
                            profile.sports
                          );
                        }}
                      >
                        Save Changes
                      </a>
                    ) : (
                      <div className="edit1" onClick={handleEditClickSports}>
                        Edit
                      </div>
                    )
                  }
                </div>
              </div>

              <div className="firstgenerationbox">
                <p className="state-of-residence">Perfoming Arts</p>
                <div className="birthday-box-child" />
                <div
                  className={
                    isEditingPerfomingArts
                      ? "edit-details-box"
                      : "display-details-box"
                  }
                >
                  {/* <p className="yes">Yes</p> */}

                  {isEditingPerfomingArts ? (
                    <select
                      name="perfomingArts"
                      required="required"
                      className={isEditingPerfomingArts ? "t" : "yes"}
                      placeholder={profile.perfomingArts}
                      value={inputValuePerfomingArts}
                      onChange={handleInputChangePerfomingArts}
                      disabled={!isEditingPerfomingArts}
                    >
                      <option value="">Select Arts</option>
                      <option value="Dance">Dance</option>
                      <option value="Music">Music</option>
                      <option value="Drama">Drama</option>
                    </select>
                  ) : (
                    <div className="yes">
                      <p>{profile.perfomingArts}</p>
                    </div>
                  )}
                  {
                    // onClick={handleEditClick}>{isEditing ? 'Cancel' : 'Edit'}
                    isEditingPerfomingArts ? (
                      <a
                        className="save-changes"
                        name="perfomingArts"
                        onClick={(e) => {
                          saveChanges(
                            e.target.name,
                            inputValuePerfomingArts,
                            setIsEditingPerfomingArts,
                            profile.perfomingArts
                          );
                        }}
                      >
                        Save Changes
                      </a>
                    ) : (
                      <div
                        className="edit1"
                        onClick={handleEditClickPerfomingArts}
                      >
                        Edit
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ViewProfile;
