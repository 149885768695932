import React, { useState } from "react";
import "./CoachHomepage.css";
import "./Dashboard.css";

const Sidebar = () => {
  const [activeLink, setActiveLink] = useState("Home");

  const handleLinkClick = (linkName) => {
    setActiveLink(linkName); // Update the active link
  };

  return (
    <div className="sidebar">
      <nav>
        <a
          href="#"
          className={`first-one ${activeLink === "Home" ? "active" : ""}`}
          onClick={() => handleLinkClick("Home")}
        >
          <i className="fas fa-th-large"></i> Home
        </a>
        <a
          href="#"
          className={`other-one ${activeLink === "Calendar" ? "active" : ""}`}
          onClick={() => handleLinkClick("Calendar")}
        >
          <i className="fas fa-calendar-alt"></i> Calendar
        </a>
        <a
          href="#"
          className={`other-one ${activeLink === "Bookings" ? "active" : ""}`}
          onClick={() => handleLinkClick("Bookings")}
        >
          <i className="fas fa-book"></i> Bookings
        </a>
        <a
          href="#"
          className={`other-one ${activeLink === "Messages" ? "active" : ""}`}
          onClick={() => handleLinkClick("Messages")}
        >
          <i className="fas fa-envelope"></i> Messages
        </a>
        <a
          href="#"
          className={`other-one ${activeLink === "Payments" ? "active" : ""}`}
          onClick={() => handleLinkClick("Payments")}
        >
          <i className="fas fa-dollar-sign"></i> Payments
        </a>
        <a
          href="#"
          className={`other-one ${activeLink === "Resources" ? "active" : ""}`}
          onClick={() => handleLinkClick("Resources")}
        >
          <i className="fas fa-lightbulb"></i> Resources
        </a>
        <a
          href="#"
          className={`other-one ${
            activeLink === "Scholarships" ? "active" : ""
          }`}
          onClick={() => handleLinkClick("Scholarships")}
        >
          <i className="fas fa-graduation-cap"></i> Scholarships
        </a>
        <a
          href="#"
          className={`last-one ${activeLink === "Settings" ? "active" : ""}`}
          onClick={() => handleLinkClick("Settings")}
        >
          <i className="fas fa-cog"></i> Settings
        </a>
      </nav>
    </div>
  );
};

const DashboardMainContent = () => {
  return (
    <div className="dashboard-main-content">
      {/* Welcome Section */}
      <div className="welcome-section">
        <h2>
          Welcome Back <span className="coach-name">Coach Winters</span>
        </h2>
      </div>

      <div className="com-stat">
        <h2>Community Statics</h2>
      </div>

      {/* Community Statistics Section */}
      <div className="community-stats">
        <div className="stat-item">
          <i className="fas fa-star icon"></i>
          <p>5 Star Rating</p>
          <span>10 Reviews</span>
        </div>
        <div className="stat-item">
          <i className="fas fa-dollar-sign icon"></i>
          <p>$400.00 USD</p>
          <span>Total Revenue Earned</span>
        </div>
        <div className="stat-item">
          <div className="icon-books">
            <i className="fas fa-book"></i>
            <i className="fas fa-book"></i>
          </div>
          <p>6</p>
          <span>Total Bookings</span>
        </div>
      </div>

      <div className="com-stat2">
        <h3>Upcoming Events</h3>
      </div>

      {/* Upcoming Events Section */}
      <div className="upcoming-events">
        <div className="up-event"></div>
        <div className="up-event"></div>
        <div className="up-event"></div>
      </div>
    </div>
  );
};

const RightContent = () => {
  return (
    <div className="right-content">
      <div className="profile-completion">
        <a className="dashboardheadercompleteboxtext">Comp5TYG lete your profile</a>
        <p>Maximize your impact! A complete profile boosts your visibility.</p>;'
        ;/,'
        <ul>
          <li>
            <span className="completed-task">Verify your email</span>
          </li>
          <li>
            <span className="pending-task">
              Setup Coaching Calendar — Connect with students
            </span>
          </li>
        </ul>
        <div className="progress-bar">
          <div className="progress" style={{ width: "80%" }}>
            80%
          </div>
        </div>
      </div>

      {/* Calendar Section */}
    </div>
  );
};

// Main Dashboard component combining everything
const CoachDashboard = () => {
  const [activeLink, setActiveLink] = useState("Home");

  const handleLinkClick = (linkName) => {
    setActiveLink(linkName);
  };

  return (
    <div className="coach-dashboard">
      <Sidebar activeLink={activeLink} onLinkClick={handleLinkClick} />
      <DashboardMainContent />
      <RightContent />
    </div>
  );
};

export default CoachDashboard;
